import React from 'react';
import './Pagination';

const Pagination = ({ total, currentPage, onPageChange }) => {
  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        ❮
      </button>
      <span className="page-info">{currentPage} / {total}</span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === total}
      >
        ❯
      </button>
    </div>
  );
};

export default Pagination;
