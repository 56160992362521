import React, { useState, useEffect } from "react";
import Keyword from "./Keyword";

// Helper function to escape special characters for regex
const escapeRegex = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

// Main component
const TranscriptText = ({
  text,
  keywords,
  callbackForAudio,
  userResponses,
  setUserResponses,
  callbackForKeywords,
}) => {
  const [keywordCount, setKeywordCount] = useState(0);

  // Function to split sentence and find the keywords
  const splitSentenceWithKeywords = (sentence, keywords) => {
    let splitSentence = sentence;

    // Replace each occurrence of source_language with a special marker
    keywords.forEach((keyword) => {
      const escapedKeyword = escapeRegex(keyword.source_language);
      const regex = new RegExp(`(${escapedKeyword})`, "iu");
      splitSentence = splitSentence.replace(
        regex,
        `[SPLIT_HERE]${keyword.source_language}[SPLIT_HERE]`
      );
    });

    // Split the string by the marker
    return splitSentence.split("[SPLIT_HERE]").filter(Boolean);
  };

  // Function to find keyword and get its translation
  const findKeyword = (word) => {
    const keyword = keywords.find(
      (k) => word.toLowerCase() === k.source_language.toLowerCase()
    );
    return keyword ? keyword.target_language : null;
  };

  // Track the number of matched keywords
  const countMatchedKeywords = (words) => {
    let count = 0;
    words.forEach((word) => {
      const translations = findKeyword(word);
      if (translations) {
        count++;
      }
    });
    return count;
  };

  // Handle keyword counting and callback execution
  useEffect(() => {
    const words = splitSentenceWithKeywords(text, keywords);
    const matchedCount = countMatchedKeywords(words);

    if (matchedCount !== keywordCount) {
      setKeywordCount(matchedCount); // Update the matched count state
      callbackForKeywords(matchedCount); // Trigger the callback with the updated count
    }
  }, [text, keywords, keywordCount, callbackForKeywords]);

  // Process the sentence into words
  const words = splitSentenceWithKeywords(text, keywords);

  return (
    <p>
      {words.map((word, index) => {
        const translations = findKeyword(word);
        if (translations) {
          return (
            <Keyword
              key={index}
              word={word}
              translations={translations}
              callbackForAudio={callbackForAudio}
              userResponses={userResponses}
              setUserResponses={setUserResponses}
            />
          );
        }
        return (
          <span key={index} style={{ marginRight: "4px" }}>
            {word}
          </span>
        );
      })}
    </p>
  );
};

export default TranscriptText;
