import React, { useState } from "react";
import "./EvaluateModal.css";

function EvaluateModal({ isOpen, onNowClick, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="audio-save-modal-overlay">
      <div className="audio-save-modal-content">
        <div className="header-audio-button">
          <h3>Evaluate Your Interpretation</h3>
          <span
            aria-hidden="true"
            onClick={onClose}
            className="sharemodal-close"
          >
            <i class="bi bi-x-circle"></i>
          </span>
        </div>
        <div className="modal-footer">
          <div className="footer-btn">
            <button className="now-btn" onClick={onNowClick}>
              Now
            </button>
          </div>
          <div className="footer-btn">
            <button className="later-btn" onClick={onClose}>
              Save and Return to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvaluateModal;
