import React from "react";
import "./PractiseShareModal.css";
import images from "../../../images";
// import { FaRegSmile } from "react-icons/fa";

function PracticeShareModal({
  modalShow,
  setModalShow,
  getStartClick,
  openLogin,
}) {
  //   const onClose = () => {
  //     setModalShow(false);
  //   };
  return (
    <>
      {modalShow && (
        <div
          className="practise-share-overlay"
          // onClick={() => setModalShow(false)}
        >
          <div
            className="practiceShareModal"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="practise-share-modal-content-container">
              {/* <span
                aria-hidden="true"
                onClick={onClose}
                className="practise-sharemodal-close-btn"
              >
                &times;
              </span> */}
              {/* <h4 className="share-practise-para" style={{ color: "black" }}>
                An Interpreting Practice Has Been Shared
              </h4> */}
              <div>
                <img
                  src={images.bigLogo}
                  className="practise-sharemodal-logo"
                />
              </div>
              <button
                className="practise-share-start-button"
                onClick={getStartClick}
              >
                Get Started Free
              </button>
              <p className="practise-share-login-prompt">
                Already have an account?{" "}
                <span className="practise-share-login-link" onClick={openLogin}>
                  Log in here.
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PracticeShareModal;
