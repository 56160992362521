import React from "react";
import "./HeaderLogo.css"; // Create this file for styling
import images from "../../images";

const HeaderLogo = () => {
  return (
    <div className="logo-header">
      <div className="header-logo">
        <img
          src={images.bigLogo} // Replace with your logo path
          alt="Logo"
          className="header-logo-img"
        />
      </div>
      <div className="headerlogo-text">
        <span className="orange">INTERPRE</span>
        <span className="white">TRAIN</span>
      </div>
    </div>
  );
};

export default HeaderLogo;
