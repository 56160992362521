import React, { useState, useEffect } from 'react';
import './Quiz.css';

const Quiz = ({ question, answers, correct, onAnswerSelect }) => {
  const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState(null);
  const [randomizedAnswers, setRandomizedAnswers] = useState([]);

  // Reset selected answer and re-randomize options when the question changes
  useEffect(() => {
    setCurrentSelectedAnswer(null);
    setRandomizedAnswers(answers.map((answer) => getRandomValueFromString(answer)));
  }, [question, answers]);

  const handleAnswerClick = (answer, index) => {
    if (currentSelectedAnswer === null) {
      setCurrentSelectedAnswer(answer);
      onAnswerSelect(answer); // Notify parent of the selection
    }
  };

  const getRandomValueFromString = (commaSeparatedString) => {
    const array = commaSeparatedString.split(',').map((item) => item.trim());
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  return (
    <div className="quiz">
      <h4>{question}</h4>
      <div className="answers">
        {randomizedAnswers.map((randomAnswer, index) => (
          <div key={index} className="answer-container">
            <button
              onClick={() => handleAnswerClick(answers[index], index)}
              className={`answer ${
                currentSelectedAnswer === answers[index] || 
                (currentSelectedAnswer && answers[index] === correct)
                  ? answers[index] === correct
                    ? 'correct'
                    : 'incorrect'
                  : ''
              }`}
              disabled={currentSelectedAnswer !== null}
            >
              {randomAnswer}
            </button>

            {currentSelectedAnswer === answers[index] && answers[index] !== correct && (
              <p className="your-answer-label">Your Answer</p>
            )}

            {currentSelectedAnswer && answers[index] === correct && (
              <p className="correct-answer-label">Correct Answer</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quiz;
