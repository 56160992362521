// import React, { useContext, useEffect, useState } from "react";
// import "./UpdateProfile.css";
// import HeaderComponent from "../../components/Header/Header";
// import { UserContext } from "../../providers/UserContext";

// function UpdateProfile() {
//   const { currentUser } = useContext(UserContext);
//   // console.log(currentUser, "l");
//   const [formData, setFormData] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//   });

//   useEffect(() => {
//     if (currentUser) {
//       // Populate the form data with currentUser's details
//       setFormData((prevData) => ({
//         ...prevData,
//         first_name: currentUser.first_name || "", // Fallback to empty string if currentUser doesn't have the property
//         last_name: currentUser.last_name || "",
//         email: currentUser.email || "",
//       }));
//     }
//   }, [currentUser]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form data submitted: ", formData);
//   };

//   return (
//     <div className="min-screen ">
//       <HeaderComponent />
//       <div className="update-main-content">
//         <div className="profile-card">
//           <h2 className="update-heading">Update Profile</h2>
//           <form onSubmit={handleSubmit}>
//             <div className="form-group mt-2">
//               <label className="update-label">First Name</label>
//               <input
//                 type="text"
//                 name="first_name"
//                 className="form-control"
//                 placeholder="Enter your first name"
//                 value={formData.first_name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group mt-2">
//               <label className="update-label">Last Name</label>
//               <input
//                 type="text"
//                 name="last_name"
//                 className="form-control"
//                 placeholder="Enter your last name"
//                 value={formData.last_name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             {/* <div className="form-group mt-2">
//               <label className="update-label">Password</label>
//               <input
//                 type="email"
//                 name="email"
//                 className="form-control"
//                 placeholder="Enter your new password"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div> */}

//             <button type="submit" className="btn-update mt-4">
//               Save Changes
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UpdateProfile;
import React, { useContext, useEffect, useState } from "react";
import "./UpdateProfile.css";
import HeaderComponent from "../../components/Header/Header";
import { UserContext } from "../../providers/UserContext";
import { toast } from "react-toastify";
import { updateProfile } from "../../services/user.service";
import { Navigate, useNavigate } from "react-router";

function UpdateProfile() {
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    if (currentUser) {
      // Populate the form data with currentUser's details
      setFormData((prevData) => ({
        ...prevData,
        first_name: currentUser.first_name || "", // Fallback to empty string if currentUser doesn't have the property
        last_name: currentUser.last_name || "",
        email: currentUser.email || "",
      }));
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = currentUser?.access;

      if (!accessToken) {
        throw new Error("Access token is missing. Please log in again.");
      }

      const data = await updateProfile(accessToken, {
        first_name: formData.first_name,
        last_name: formData.last_name,
      });
      if (data) {
        // console.log("Profile updated successfully:", data);
        toast.success("Profile Updated Successfully");
        const updated = {
          ...currentUser,
          first_name: formData.first_name,
          last_name: formData.last_name,
        };
        updateCurrentUser(updated);
      }
    } catch (error) {
      // console.error("Failed to update profile:", error);
      toast.error(
        error.message || "Failed to update profile. Please try again."
      );
    }
  };
  const onClose = () => {
    navigate(-1);
  };
  return (
    <div className="min-screen">
      <HeaderComponent />
      <div className="update-main-content">
        <div className="profile-card">
          <div className="profile-update-close">
            <h2 className="update-heading">Update Profile</h2>
            <span
              aria-hidden="true"
              onClick={onClose}
              className="profile-update-close-btn"
            >
              <i class="bi bi-x-circle"></i>
            </span>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="form-group mt-2">
              <label className="update-label">First Name</label>
              <input
                type="text"
                name="first_name"
                className="form-control"
                placeholder="Enter your first name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-2">
              <label className="update-label">Last Name</label>
              <input
                type="text"
                name="last_name"
                className="form-control"
                placeholder="Enter your last name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="btn-update mt-4">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
