import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("refreshTokens") ? true : false;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/practice-dashboard");
    }
    console.log("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  return !isAuthenticated ? children : null;
};

export default RequireAuth;
