import axios from "axios";
import { REACT_APP_GENERATE_AUDIO, REACT_APP_UPDATE_AUDIO, REACT_APP_SAVE_AUDIO, REACT_APP_RECORDING_LISTS, REACT_APP_GET_RECORDINGS_BY_INEDTIFIER_API, REACT_APP_RECORDINGS_AUDIO_SAVE } from "../constants";
import { toast } from "react-toastify";



// Function to generate audio //
export const generateAudio = async (accessToken, identifier, voice, speed, wpm, styleName) => {

  if (!accessToken) {
    throw new Error("No access token found.");
  }
  try {
    const dataToSend = {
      identifier,
      voice,
      speed,
      wpm,
      style_name: styleName,
    };

    const response = await fetch(REACT_APP_GENERATE_AUDIO, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {

      const errorData = await response.json();
      console.error("Failed to generate audio:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error generating audio:", error);
    throw error;
  }
};


////save audio //


export const submitAudioService = async (dataToSend, isComplete) => {
  try {
    const accessToken = localStorage.getItem("accessToken");


    const url = isComplete
      ? `${REACT_APP_UPDATE_AUDIO}${dataToSend.identifier}/`
      : `${REACT_APP_SAVE_AUDIO}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Failed to submit audio:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Error submitting the audio:", error);
    throw error;
  }
};


//// fecth recording //


export const fetchRecordingsService = async (email, identifier, token) => {
  try {
    const response = await axios.post(
      REACT_APP_RECORDING_LISTS,
      { email: email, identifier: identifier },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );


    return response.data;

  } catch (error) {
    console.error("Error fetching the recordings:", error);
    throw error;
  }
};


// Define the audio submission service
export const submitRecordingService = async (id, audioFile, audioName, removeSilence, email, token) => {
  try {
    // const url = process.env.REACT_APP_RECORDINGS_AUDIO_SAVE;

    // Create FormData object
    const formData = new FormData();
    formData.append("identifier", id);
    formData.append("recording_name", audioName);
    formData.append("is_silent", removeSilence);
    formData.append("recording_file", audioFile); // Add the actual audio file (Blob)
    formData.append("userId", email);
    formData.append("change_audio_format_to_mp3", "No");


    // Make the POST request with FormData
    const postResponse = await axios.post(REACT_APP_RECORDINGS_AUDIO_SAVE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    if (postResponse.status === 201 || postResponse.status === 200) {
      toast.success("Recording Submitted Successfully.");
      return { success: true, data: postResponse.data };
    } else {
      toast.error("Error submitting audio.");
      return { success: false };
    }
  } catch (error) {
    console.error("Error submitting the audio:", error);
    toast.error("Error submitting the audio.");
    return { success: false, error };
  }
};

// Service to fetch recordings by identifier
export const fetchRecordingsByIdentifier = async (id, token) => {
  try {
    const response = await axios.post(
      REACT_APP_GET_RECORDINGS_BY_INEDTIFIER_API,
      { identifier: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      return { success: true, data: response.data };
    } else {
      throw new Error("No data received");
    }
  } catch (error) {
    console.error("Error fetching the transcript:", error);
    return { success: false, error: error.message };
  }
};