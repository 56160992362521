import React from "react";
import "./AudioPlay.css";

const AudioPlayModal = ({ isOpen, onClose, onStart }) => {
  if (!isOpen) return null;
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("AudioPlay-modal-overlay")) {
      onClose();
    }
  };
  return (
    <div className="AudioPlay-modal-overlay">
      <div className="AudioPlay-modal-content">
        <div>
          <div className="Audioplay-close-div">
            <span className="AudioPlay-close-button" onClick={onClose}>
              <i className="bi bi-x-circle"></i>
            </span>
          </div>
          <h3>Practice Instructions:</h3>
          <ul className="modal-instructions">
            <li>
              Verify that your microphone is connected by checking the sound bar
              below.
              <br /> If connected, the bar will respond to sound input.
            </li>
            <li>
              When ready, click the "Let's Start" button to activate the audio.
            </li>
            <li>After a 5-second countdown, begin interpreting.</li>
            <li>When you finish your interpretation, click "Stop & Save".</li>
          </ul>
          <button
            className="control-button interpret-control mt-1"
            onClick={onStart}
          >
            Let's Start
          </button>
        </div>
      </div>
    </div>
  );
};
export default AudioPlayModal;
