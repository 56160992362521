import axios from "axios";
import { REACT_APP_GET_TRANSCRIPT_API, REACT_APP_GET_TRANSCRIPT_PUBLIC_API, REACT_APP_GET_VOCAB_API, REACT_APP_SAVE_TRANSCRIPT_API, REACT_APP_TRANSCRIPT_API, REACT_APP_UPDATE_TRANSCRIPTS } from "../constants";


export const submitTranscriptData = async (dataToSend, accessToken, maxRetries = 2) => {
  if (!accessToken) {
    throw new Error("No access token found.");
  }
  let retryCount = 0;
  let success = false;

  while (retryCount <= maxRetries && !success) {
    try {
      const response = await fetch(REACT_APP_TRANSCRIPT_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        if (response.status === 401) {
          throw new Error("Unauthorized. Please check your credentials.");
        } else if (response.status >= 500) {
          retryCount += 1;
          if (retryCount > maxRetries) {
            throw new Error("Max retries reached. Please try again later.");
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorText}`);
        }
      }
    } catch (error) {
      if (retryCount >= maxRetries) {
        throw error;
      }
      retryCount += 1;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};


////save transcript //


// Function to save transcript
export const saveTranscript = async (accessToken, identifier, transcriptData) => {
  try {
    const response = await fetch(REACT_APP_SAVE_TRANSCRIPT_API, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(transcriptData),
    });

    if (!response.ok) {

      const errorData = await response.json();
      console.error("Failed to save transcript:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error saving transcript:", error);
    throw error;
  }
};

/// update //

export const updateTranscript = async (accessToken, identifier, transcriptData) => {
  try {
    const response = await fetch(`${REACT_APP_UPDATE_TRANSCRIPTS}${identifier}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(transcriptData),
    });

    if (!response.ok) {

      const errorData = await response.json();
      console.error("Failed to save transcript:", errorData);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error saving transcript:", error);
    throw error;
  }
};


//  fetch transcript //
export const fetchTranscriptService = async (identifier, token) => {
  try {
    const response = await axios.post(
      REACT_APP_GET_TRANSCRIPT_API,
      { identifier: identifier },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );


    return {
      transcript: response.data.transcripts[0],
      audios: response.data.temporary_audios,
      keyword: response.data.keyword,
      save_audios: response.data.save_audios,
      user_details: response.data.user_details,
    };
  } catch (error) {
    console.error("Error fetching the transcript:", error);
    throw error;
  }
};

//  fetch transcript //
export const fetchTranscriptServicePublic = async (identifier) => {
  try {
    const response = await axios.post(
      REACT_APP_GET_TRANSCRIPT_PUBLIC_API,
      { identifier: identifier },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      }
    );


    return {
      transcript: response.data.transcripts[0],
      audios: response.data.temporary_audios,
      keyword: response.data.keyword,
      save_audios: response.data.save_audios,
      user_details: response.data.user_details,
    };
  } catch (error) {
    console.error("Error fetching the transcript:", error);
    throw error;
  }
};

////

export const fetchSourceLanguageService = async (accessToken, identifier) => {
  try {
    const response = await axios.post(
      REACT_APP_GET_VOCAB_API,
      { identifier: identifier },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data
  } catch (error) {
    console.error("Error fetching source language:", error.response || error.message);
    throw error;
  }
};


