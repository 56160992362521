import React from "react";
import "./CommanCase.css";
import HeaderLogo from "../../components/HeaderLogo/HeaderLogo";
import HeaderComponent from "../../components/Header/Header";
const CommonUseCases = () => {
  return (
    <div className="use-cases-container">
      <div className="comancase-logo">
        <HeaderComponent />
      </div>
      <div className="comman-case-heading">
        <h1 className="use-cases-heading">Common Use Cases</h1>
      </div>
      <div className="commancase-center">
        <div className="commancase-center-one">
          <div className="use-case">
            <h2>1. Interpreter Exam Preparation</h2>
            <p>
              Prepare comprehensively for your interpreter exams with a diverse
              range of practice scenarios. Generate realistic scenarios to
              supplement your study materials. Easily share recordings with
              study groups, tutors, or mentors for valuable feedback and
              evaluation.
            </p>
          </div>
          <div className="use-case">
            <h2>2. Private Tutors/Professors/Trainers</h2>
            <h3>a) Customized Practice Scenarios</h3>
            <p>
              Create tailored practice exercises that match the skill level of
              individual students or groups. Gradually increase the difficulty
              to challenge and promote growth. Students can easily share
              recordings for your assessment and guidance.
            </p>
            <h3>b) Shareable Practice Resources</h3>
            <p>
              Generate unique URLs for each practice scenario, which you can
              share directly with students via email or integrate into your own
              learning platform. Create courses with embedded practice URLs for
              a comprehensive learning experience or enhance existing content.
            </p>
          </div>
          <div className="use-case">
            <h2>3. Assignment Preparation</h2>
            <p>
              Quickly generate practice scenarios that target your upcoming
              assignments. Seek feedback from colleagues or mentors by sharing
              your recordings. Fine-tune your skills by increasing the pace or
              duration of the practice.
            </p>
          </div>
          <div className="use-case">
            <h2>4. Social Media/Brand Awareness</h2>
            <p>
              Showcase your expertise and build your brand by sharing practice
              scenarios on social media platforms like Facebook and Twitter.
              Generate custom URLs to include in newsletters, email campaigns,
              or resource pages, expanding your reach and engagement.
            </p>
          </div>
          <div className="use-case">
            <h2>5. Exploration for Beginners</h2>
            <p>
              If you've been interpreting informally for friends and family and
              are curious about taking your skills further, use the platform to
              explore your potential. Create practice scenarios to assess your
              interest in pursuing a career in interpreting. If you decide to
              proceed, leverage the platform to support your ongoing development
              and training.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonUseCases;
