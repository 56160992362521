import React from "react";
import images from "../../../images";
import "./voabdelet.css";

const ConfirmDeleteModal = ({ showModal, onClose, onDelete, message }) => {
  return (
    <>
      {showModal && <div className="modal-backdrop-blur" />}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div
          className="delete-modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="delete-modal-content">
            <div className="modal-body delete-modal-body">
              <div className="delete-item">
                <img src={images.deleteimg} alt="delete" />
                {/* <h4>Are you sure?</h4> */}
                <p>{message}</p>
              </div>
              <div className="delete-modal-btn-group">
                <button type="button" className="canclebtn" onClick={onClose}>
                  Cancel
                </button>
                <button type="button" className="deletbtn" onClick={onDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDeleteModal;
