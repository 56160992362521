import React from 'react';

const Tabs = ({ activeTab, onTabClick, tabs }) => {
  return (
    <ul className="nav nav-tabs card-tabs" role="tablist">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab.id}>
          <button
            type="button"
            className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => onTabClick(tab.id)}
            role="tab"
            aria-selected={activeTab === tab.id} // ARIA attribute for better accessibility
          >
            {tab.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
