export const REACT_APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_BASE_URL;
// console.log("API_URL", API_URL);
const API_BASE_URL = `${API_URL}/api/v1`;

export const REACT_APP_LOGIN_API = `${API_BASE_URL}/login/`;
export const REACT_APP_REGISTER_API = `${API_BASE_URL}/register/`;
export const REACT_APP_FORGOT_PASSWORD = `${API_BASE_URL}/forget_password/`;
export const REACT_APP_RESET_PASSWORD = `${API_BASE_URL}/forget_password_confirm/`;
export const REACT_APP_USER_DETAILS = `${API_BASE_URL}/user-details/`;
export const REACT_APP_PROFILE_UPDATE = `${API_BASE_URL}/profile/update/`;
export const REACT_APP_TOPIC_INFO_API = `${API_BASE_URL}/generate/questions/`;
export const REACT_APP_VOCAB_API = `${API_BASE_URL}/gemini/`;
export const REACT_APP_SAVE_VOCAB_API = `${API_BASE_URL}/save-vocabulary/`;
export const REACT_APP_UPDATE_VOCAB_API = `${API_BASE_URL}/update-vocabulary/`;
export const REACT_APP_GET_VOCAB_API = `${API_BASE_URL}/get-data-by-identifier/`;
export const REACT_APP_GET_KEYWORDS_API = `${API_BASE_URL}/flash-card/`;
export const REACT_APP_COPY_VOCAB_API = `${API_BASE_URL}/copy-practice-info/`;
export const REACT_APP_ADD_SHARED_PRACTICE_LIST = `${API_BASE_URL}/add-shared-practice-list/`;
export const REACT_APP_DASHBOARD = `${API_BASE_URL}/topics-by-email/`;
export const REACT_APP_DASHBOARD_SHARED_PRACTICE = `${API_BASE_URL}/shared-practice-list/`;
export const REACT_APP_TRANSCRIPT_API = `${API_BASE_URL}/transcript/`;
export const REACT_APP_SAVE_TRANSCRIPT_API = `${API_BASE_URL}/save-transcript/`;
export const REACT_APP_GET_TRANSCRIPT_API = `${API_BASE_URL}/get-data-by-identifier/`;
export const REACT_APP_GET_TRANSCRIPT_PUBLIC_API = `${API_BASE_URL}/get-data-by-identifier-public/`;
export const REACT_APP_SOFT_DELETE_KEYWORD = `${API_BASE_URL}/soft-delete-keyword/`;
export const REACT_APP_GENERATE_AUDIO = `${API_BASE_URL}/generate-audio/`;
export const REACT_APP_SAVE_AUDIO = `${API_BASE_URL}/save-audio/`;
export const REACT_APP_UPDATE_AUDIO = `${API_BASE_URL}/update-save-audio/`;
export const REACT_APP_UPDATE_TRANSCRIPTS = `${API_BASE_URL}/update-transcripts/`;
export const REACT_APP_LOGOUT = `${API_BASE_URL}/signout/`;
export const REACT_APP_RECORDING_LISTS = `${API_BASE_URL}/recordings/list/`;
export const REACT_APP_DELETE_RECORDING = `${API_BASE_URL}/recordings/delete/`;
export const REACT_APP_RECORDINGS_AUDIO_SAVE = `${API_BASE_URL}/recordings/`;
export const REACT_APP_SINGLE_REGENERATE = `${API_BASE_URL}/keyword-regenerate/`;
export const REACT_APP_GET_RECORDINGS_BY_INEDTIFIER_API = `${API_BASE_URL}/recordings/identifier/`;
// export const REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE = 3
export const REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE = 1
export const REACT_APP_DELETE_ALL_RECORDING = `${API_BASE_URL}/recordings/delete/identifier/`;


