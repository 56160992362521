import React, { useContext, useState } from "react";
import images from "../../images";
import { useNavigate } from "react-router-dom";
import "./SignUpPage.css";
import { loginUser, registerUser } from "../../services/user.service";
import { toast } from "react-toastify";
import { UserContext } from "../../providers/UserContext";
import "react-toastify/dist/ReactToastify.css";
import HeaderLogo from "../../components/HeaderLogo/HeaderLogo";
function SignUpScreen() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  // const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const { updateCurrentUser } = useContext(UserContext);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [agreementError, setAgreementError] = useState("");
  const [serverError, setServerError] = useState("");
  const [showPasswordTips, setShowPasswordTips] = useState(false); // New state
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const [isPasswordShowConfirm, setIsPasswordShowConfirm] = useState(true);

  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;

    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setUserNameError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setAgreementError("");

    if (!FirstName) {
      setFirstNameError("First Name is required.");
      toast.error("First Name is required.", {
        className: "toast-black-text",
      });
      isValid = false;
    }
    if (!LastName) {
      setLastNameError("Last Name is required.");
      toast.error("Last Name is required.", {
        className: "toast-black-text",
      });
      isValid = false;
    }
    if (!Email) {
      setEmailError("Email is required.");
      toast.error("Email is required.", {
        className: "toast-black-text",
      });
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Invalid email address.");
      toast.error("Invalid email address.", {
        className: "toast-black-text",
      });
      isValid = false;
    }
    // if (!UserName) {
    //   setUserNameError("User Name is required");
    //   isValid = false;
    // }
    if (!Password) {
      setPasswordError("Password is required.");
      toast.error("Password is required.", {
        className: "toast-black-text",
      });
      isValid = false;
    } else if (Password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      toast.error("Password must be at least 8 characters long.", {
        className: "toast-black-text",
      });
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(Password)) {
      setPasswordError("Password must contain one special character.");
      toast.error("Password must contain one special character.", {
        className: "toast-black-text",
      });
      isValid = false;
    }
    if (Password && ConfirmPassword !== Password) {
      setConfirmPasswordError("Passwords do not match.");
      toast.error("Passwords do not match.", {
        className: "toast-black-text",
      });
      isValid = false;
    }

    if (!agree) {
      setAgreementError("Please agree to the term and conditions.");
      toast.error("Please agree to the term and conditions.", {
        className: "toast-black-text",
      });

      isValid = false;
    }

    return isValid;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const userData = {
      first_name: FirstName,
      last_name: LastName,
      email: Email,
      password: Password,
      password2: ConfirmPassword,
      // UserName,
    };

    try {
      const { success, message } = await registerUser(userData);

      if (success) {
        // toast.success("Account Created Successfully");
        // navigate("/login");
        const payload = { email: Email, password: Password };
        const data = await loginUser(payload);

        if (data && data.access) {
          console.log("User data received:", data);
          updateCurrentUser(data);
          toast.success(`Welcome, ${data.first_name}.`, {
            className: "toast-black-text",
          });
          // After successful login
          const redirectUrl = localStorage.getItem("redirectAfterLogin");
          if (redirectUrl) {
            navigate(redirectUrl);
            localStorage.removeItem("redirectAfterLogin");
          } else {
            // Default redirection if no stored URL
            navigate("/welcome");
          }
        } else {
          toast.error("No access token found in response.");
        }
      } else {
        toast.error(message, {
          className: "toast-black-text",
        });
        setServerError(message);
      }
    } catch (error) {
      setServerError("Something went wrong. Please try again later.");
    }
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const openPrivacyPolicy = () => {
    window.open("https://interpretrain.com/pages/privacy-policy", "_blank");
  };

  return (
    <div className="min-screen">
      {/* <div className="logo-style">
        <img src={images.logo} />
      </div> */}
      <div className="main-content" style={{ marginTop: "10px" }}>
        <div className="Signup">
          <form onSubmit={handleSignUp}>
            {/* <img src={images.logoHigh} alt="logo" className="logo" /> */}
            <HeaderLogo />
            <h2>Create Account</h2>

            <div className="inputForm">
              <img src={images.user1} alt="mail" className="inputicon" />
              <input
                type="text"
                className="custom-input"
                placeholder="First Name"
                value={FirstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {firstNameError && (
              <div className="sign-error-message">{firstNameError}</div>
            )}
            <div className="inputForm mt-3">
              <img src={images.user1} alt="mail" className="inputicon" />
              <input
                type="text"
                className="custom-input"
                placeholder="Last Name"
                value={LastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            {lastNameError && (
              <div className="sign-error-message">{lastNameError}</div>
            )}

            <div className="inputForm mt-3">
              <img src={images.mailIcon} alt="mail" className="inputicon" />
              <input
                type="email"
                className="custom-input"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {emailError && (
              <div className="sign-error-message">{emailError}</div>
            )}
            {/* <div className="inputForm">
              <img src={images.userss} alt="mail" className="inputicon" />
              <input
                type="text"
                className="custom-input"
                placeholder="User Name"
                value={UserName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            {userNameError && (
              <div className="sign-error-message">{userNameError}</div>
            )} */}
            <div className="inputForm mt-3">
              <img src={images.lockIcon} alt="lock" className="inputicon" />
              <input
                // type={isPasswordShow ? "text" : "password"}
                input="text"
                className="custom-input"
                placeholder="Password"
                value={Password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setShowPasswordTips(true);
                }}
                onFocus={() => setShowPasswordTips(true)} // Show suggestions when focusing on the input
                onBlur={() => setShowPasswordTips(false)} // Hide suggestions when focusing out
              />
              {/* <i
                className={`bi ${isPasswordShow ? "bi-eye" : "bi-eye-slash"}`}
                alt="lock"
                style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              ></i> */}
              {/* <img
                src={isPasswordShow ? images.eyeOff : images.eye}
                alt="lock"
                className="inputicon"
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              /> */}
            </div>
            {passwordError && (
              <div className="sign-error-message">{passwordError}</div>
            )}

            <div className="inputForm mt-3">
              <img src={images.lockIcon} alt="lock" className="inputicon" />
              <input
                // type={isPasswordShowConfirm ? "text" : "password"}
                input={"text"}
                className="custom-input"
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <i
                className={`bi ${
                  isPasswordShowConfirm ? "bi-eye" : "bi-eye-slash"
                }`}
                alt="lock"
                style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
                onClick={() => setIsPasswordShowConfirm(!isPasswordShowConfirm)}
              ></i> */}
              {/* <img
                src={isPasswordShowConfirm ? images.eyeOff : images.eye}
                alt="lock"
                className="inputicon"
                onClick={() => setIsPasswordShowConfirm(!isPasswordShowConfirm)}
              /> */}
            </div>
            {confirmPasswordError && (
              <div className="sign-error-message">{confirmPasswordError}</div>
            )}
            {/* Password Suggestion Text */}
            {showPasswordTips && (
              <div className="password-suggestions">
                <p>Your password should:</p>
                <ul>
                  <li>Be at least 8 characters long</li>
                  <li>Contain at least one special character (!@#$%^&*)</li>
                  <li>Contain a mix of letters and numbers</li>
                </ul>
              </div>
            )}

            <div className="input-box mt-3 ">
              <input
                type="checkbox"
                name=""
                checked={agree}
                onChange={() => setAgree(!agree)}
              />
              <p className="terms ">
                I agree to these{" "} 
                <span
                  onClick={openPrivacyPolicy}
                  style={{
                    cursor: "pointer",
                    display: "inline",
                  }}
                >
                  terms and conditions.
                </span>
              </p>
            </div>
            {/* {agreementError && (
              <div className="sign-error-message mb-3">{agreementError}</div>
            )} */}
            {/* {serverError && (
              <div className="sign-error-message mb-2">{serverError}</div>
            )} */}
            <button type="submit" className="btn success">
              Sign up
            </button>

            <p className="last-line">
              Already have an account?{" "}
              <span
                onClick={handleSignIn}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                }}
              >
                Log in here.
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpScreen;
