// Utility function to clean and merge the transcript
export const    cleanTranscript = (transcript) => {
    // Join all array elements into one string and remove '*', ',' and <break />
    const cleanedTranscript = transcript
        .join(' ') // Merge all indices into a single string
        .replace(/\*/g, '') // Remove all '*'
        // .replace(/,/g, '')  // Remove all commas
        .replace(/<break \/>/g, '</br> </br>') // Optionally remove <break /> tags if necessary
        .trim(); // Remove any unnecessary whitespace

    return cleanedTranscript;
};