import React from "react";
import HeaderComponent from "../../../components/Header/Header";
import images from "../../../images";
import { useNavigate, useParams } from "react-router";
import Footer from "../../../components/Footer/Footer";
const VoiceCongratulation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const continueClick = () => {
    navigate(`/practice-dashboard`);
  };
  return (
    <div className="min-screen VoiceCongratulation">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content text-center">
          <img src={images.youDidIt} alt="icon-label" className="Icon_Label_you_did_it" />
          <h3 className="title">Great Job!</h3>
          <p className="des">
            You have successfully completed all steps. Your practice session is
            ready to begin.
          </p>
          <div className="btn-list">
            <button
              className="btn btn-light genearation"
              onClick={continueClick}
            >
              GO TO PRACTICE
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VoiceCongratulation;
