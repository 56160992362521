import React from "react";
import "./VocablaryList.css";
import PropTypes from "prop-types";
import images from "../../images";
import { useNavigate } from "react-router";

// console.log("Vocabulary List:", vocabularyList);
// const handleCloseClick = () => {
//   navigate("/edit-vocab-list");
// };
const VocabListModal = ({
  isOpen,
  onClose,
  vocabList,
  url,
  vocabularyDetails,
}) => {
  const navigate = useNavigate();

  //   if (!isOpen) return null;
  const EditClick = (index) => {
    console.log("Edit Clicked Index:", index);
    if (index !== undefined && index !== null) {
      navigate(url, { state: { index } });
    } else {
      console.error("Invalid Index passed to EditClick:", index);
    }
  };
  const flattenedVocabList = Array.isArray(vocabList) ? vocabList.flat() : [];
  return (
    <div className="vocab-list-modal-overlay" onClick={onClose}>
      <div className="vocab-list-modal-content">
        <div className="container">
          <h1 className="vocab-list-title">Generated Vocabulary List</h1>
          <div className="card">
            <div className="card-header">
              <p className="card-title">Generated Keywords</p>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  {flattenedVocabList.flat().map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}.</td>
                      <td>{item.source_language}</td>
                      <td>{item.target_language}</td>
                      <td>
                        <img
                          className="edit-image"
                          src={images.edit}
                          alt="edit-icon"
                          onClick={() => EditClick(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-list justify-content-center">
            <div style={{ width: "150px" }}>
              <button
                style={{ borderRadius: "100px", marginBottom: "15px" }}
                className="light"
                // ssonClick={handleCloseClick}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
VocabListModal.propTypes = {
  vocabList: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
export default VocabListModal;
