import axios from "axios";
import { REACT_APP_DELETE_ALL_RECORDING, REACT_APP_SOFT_DELETE_KEYWORD } from "../constants";

// soft delete //
export const deleteItem = async (itemToDelete, token) => {
  if (!token) {
    throw new Error("No access token found.");
  }

  if (!itemToDelete) {
    throw new Error("No item selected for deletion.");
  }

  const response = await fetch(REACT_APP_SOFT_DELETE_KEYWORD, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ identifier: itemToDelete }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const result = await response.json();
  return result;
};

export const deleteAllRecording = async (identifier, email, token) => {
  if (!token) {
    throw new Error("No access token found.");
  }

  try {
    const response = await axios.post(
      REACT_APP_DELETE_ALL_RECORDING, {
        identifier, email
      } ,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        // data: {  },  
      }
    );

    return response.data;  

  } catch (error) {
    throw new Error(`HTTP error! Status: ${error.response.status}`);
  }
};




