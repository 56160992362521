// import React from "react";

// const Footer = () => {
//   return (
//     <div className="Copyright">
//       <p>Copyright © Interpretrain, All rights reserved 2024.</p>
//     </div>
//   );
// };

// export default Footer;
import React from "react";

const Footer = () => {
  return (
    <div
      className="Copyright"
      style={{ color: "white", textAlign: "center", padding: "10px" }}
    >
      <p>Copyright © Interpretrain, All rights reserved 2024.</p>
      <p>
        Need assistance? Contact us
        <a
          href="https://interpretrain.com/pages/contact"
          target="_blank"
          style={{
            color: "white",
            textDecoration: "underline",
            textUnderlineOffset: "3px",
            marginLeft: "5px",
          }}
        >
          here
        </a>
        .
      </p>
    </div>
  );
};

export default Footer;
