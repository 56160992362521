import React from "react";
import "./TryAgainModal.css";
import images from "../../../images";

const ErrorModalComponent = ({ modalText, onClose, onClickTryAgain }) => {
  return (
    <div className="eror-modal-overlay">
      <div className="eror-modal">
        <div className="trymodal-close-div">
          <span aria-hidden="true" className="trymodal-close" onClick={onClose}>
            <i class="bi bi-x-circle"></i>
          </span>
        </div>
        <img
          src={images.wrong}
          alt="Modal Visual"
          className="eror-modal-image"
        />
        <button className="btn small mt-2" onClick={onClickTryAgain}>
          Try Again
        </button>
        <p className="eror-modal-text">{modalText}</p>
      </div>
    </div>
  );
};

export default ErrorModalComponent;
