import React, { useState, useEffect } from "react";

const SourceLanguageInput = ({
  value,
  readOnly,
  onChange,
  fieldName = "source_language",
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return isMobile ? (
    <textarea
      cols="50"
      className="form-control input-center"
      value={value}
      readOnly={readOnly}
      onChange={(e) => onChange(fieldName, e.target.value)}
    ></textarea>
  ) : (
    <input
      type="text"
      className="form-control input-center"
      value={value}
      readOnly={readOnly}
      onChange={(e) => onChange(fieldName, e.target.value)}
    />
  );
};

export default SourceLanguageInput;
