
import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({});

    const updateFormData = (key, value) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: value,
        }));
    };

    const resetFormData = () => {
        setFormData({});
    };

    return (
        <FormContext.Provider value={{ formData, updateFormData ,resetFormData}}>
            {children}
        </FormContext.Provider>
    );
};
