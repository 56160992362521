import React, { useState } from "react";
import "./AudioSave.css";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";

// function AudioSaveModal({ isOpen, onClose, onSave }) {
//   const [audioName, setAudioName] = useState("");
//   const [removeSilence, setRemoveSilence] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const handleSave = () => {
//     if (audioName) {
//       setLoading(true);
//       onSave({ audioName, removeSilence });
//       setLoading(false);
//     } else {
//       toast.error("Please enter an audio name.");
//     }
//   };
//   // const onNowClick = () => {
//   //   navigate(`/recording-with-keyword/${id}`);
//   // };

//   if (!isOpen) return null;

//   return (
//     <div className="audio-save-modal-overlay">
//       <div className="audio-save-modal-content">
//         <h3>Audio Name</h3>
//         <label>
//           Enter Audio Name:
//           <input
//             type="text"
//             value={audioName}
//             onChange={(e) => setAudioName(e.target.value)}
//             placeholder="Enter Audio Name"
//           />
//         </label>
//         <div>
//           <label>
//             <input
//               type="checkbox"
//               checked={removeSilence}
//               onChange={() => setRemoveSilence(!removeSilence)}
//             />
//             Remove Silences
//           </label>
//         </div>
//         <div className="modal-footer">
//           <button className="save-btn" onClick={handleSave}>
//             {loading ? "Saving..." : "Save"}
//           </button>
//           <button className="close-btn" onClick={onClose}>
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }
function AudioSaveModal({ isOpen, onClose, onSave }) {
  const [audioName, setAudioName] = useState("");
  const [removeSilence, setRemoveSilence] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    if (audioName) {
      setLoading(true);
      onSave({ audioName, removeSilence }).finally(() => {
        setLoading(false);
      });
      setAudioName("");
    } else {
      toast.error("Please enter an audio name.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="audio-save-modal-overlay">
      <div className="audio-save-modal-content">
        <h3>Audio Name</h3>
        <label>
          Enter Audio Name:
          <input
            type="text"
            value={audioName}
            onChange={(e) => setAudioName(e.target.value)}
            placeholder="Enter Audio Name"
          />
        </label>
        <div>
          <label>
            <input
              type="checkbox"
              checked={removeSilence}
              onChange={() => setRemoveSilence(!removeSilence)}
            />
            Remove Silences
          </label>
        </div>
        <div className="modal-footer">
          <button className="save-btn" onClick={handleSave} disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </button>
          <button className="close-btn" onClick={onClose} disabled={loading}>
            Close
          </button>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}
export default AudioSaveModal;
