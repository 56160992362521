import React from "react";
import "./Overlay.css";

const Overlay = ({ children, isVisible }) => {
  if (!isVisible) return null;

  return <div className="overlay">{children}</div>;
};

export default Overlay;
