
import React, { useState, useEffect } from 'react';
import './Card.css'; // We'll define the CSS later

const Card = ({ frontContent, backContent, isFront }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  // Whenever the isFront prop changes, reset the flip state
  useEffect(() => {
    console.log(isFront, "isFront");
    setIsFlipped(false);
  }, [isFront]);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="card-container">
      <div className={`card ${isFlipped ? 'flipped' : ''}`}>
        <div className="card-front" onClick={handleFlip} >
          {frontContent}
        </div>
        <div className="card-back" onClick={handleFlip} >
          {backContent}
        </div>
      </div>
      <button onClick={handleFlip} className="flip-button">
        {isFlipped ? 'Show Front' : 'Show Back'}
      </button>

    </div>
  );
};

export default Card;
