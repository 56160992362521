import React from "react";
import "./ShareModal.css";
import images from "../../../images";
// import images from "../../../images";
// import facebook from "../assets/Images/facebook.svg";
// import twiter from "../assets/Images/prime_twitter.svg";
const ShareModal = ({ onClose, url, onCopy }) => {
  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  return (
    <div
      className="modal share-madal fade show"
      style={{ display: "block" }}
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
        style={{ height: "auto" }}
      >
        <div className="modal-content" style={{ maxWidth: "600px" }}>
          <div className="modal-header d-flex justify-content-between p-1">
            <h5 className="share-modal-title" id="exampleModalLongTitle">
              <i class="bi bi-share" style={{ fontSize: "25px" }}></i>
              &nbsp; Share this Practice
            </h5>
            {/* <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            > */}
            <span
              aria-hidden="true"
              onClick={onClose}
              className="sharemodal-close-btn"
            >
              <i class="bi bi-x-circle"></i>
            </span>
            {/* </button> */}
          </div>
          <div className="modal-body p-2">
            <div className="share-url mb-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="input-box mb-2">
                    <p
                      style={{
                        width: "40px",
                        transform: "rotate(45deg)",
                      }}
                    >
                      {/* Set url */}
                      <i class="bi bi-link" style={{ fontSize: "20px" }}></i>
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      value={url}
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="col-sm-3 p-0">
                  <button
                    className="btn btn-primary mt-0"
                    style={{ width: "127px" }}
                    onClick={onCopy}
                  >
                    Copy link
                  </button>
                </div> */}
              </div>
            </div>

            {/* <div className="verify-with-check">
              <label className="checkbox-container">
                <span>
                  I give permission for my shared practice to be copied and
                  edited.
                </span>
                <input type="checkbox" checked="checked" readOnly />
                <span className="checkmark"></span>
              </label>
            </div>

            <div className="verify-with-check">
              <label className="checkbox-container">
                <span>
                  I do not give permission for my shared practice to be copied
                  and edited.
                </span>
                <input type="checkbox" readOnly />
                <span className="checkmark"></span>
              </label>
            </div> */}

            <div className="share-with mt-4">
              <button
                className="facebook-btn"
                id="facebook-button"
                onClick={onCopy}
              >
                <i
                  class="bi bi-copy"
                  style={{ fontSize: "17px", marginRight: "15px" }}
                ></i>
                Copy link
              </button>

              <button
                className="facebook-btn"
                id="facebook-button"
                onClick={shareOnFacebook}
              >
                <img
                  src={images.facebook}
                  style={{ marginRight: "14px" }}
                  alt="facebook-icon"
                />
                Share on Facebook
              </button>
              <button className="twitter-btn" onClick={shareOnTwitter}>
                <img
                  src={images.twitter}
                  style={{ marginRight: "14px" }}
                  alt="prime_twitter-icon"
                />
                Share on X
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
