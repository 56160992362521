import React from 'react';

const TabContent = ({ activeTab, tabId, children }) => {
  return (
    <div className={`tab-pane ${activeTab === tabId ? 'active' : ''}`} id={tabId} role="tabpanel">
      {children}
    </div>
  );
};

export default TabContent;
