import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";
import axios from "axios";
import {
  API_URL,
  REACT_APP_DELETE_RECORDING,
  REACT_APP_URL,
} from "../../constants";
import { useNavigate, useParams } from "react-router";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import Footer from "../../components/Footer/Footer";
import "./RecordingWithKeyword.css";
import DeleteListModal from "../../components/Modal/AudioList/AudioList";
import { toast } from "react-toastify";
import { fetchRecordingsService } from "../../services/audio.service";
import { fetchTranscriptService } from "../../services/transcript.service";
import { UserContext } from "../../providers/UserContext";
import TranscriptText from "./components/TranscriptText";
import images from "../../images";
import { deleteAllRecording } from "../../services/api.service";
import Research from "./components/Research/Research";
// import Research from "./components/ResearchComponent/ResearchBk";

import ConfirmDeleteModal from "../../components/Modal/DeleteModal/VocabDeletModal";
import ResearchSelected from "./components/ResearchSelected/ResearchSelected";
import ResearchModal from "../../components/Modal/Research/ResearchModal";

const RecordingWithKeyword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [transcriptText, setTranscriptText] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const { currentUser } = useContext(UserContext);
  const [reasearchshowModal, setReserchShowModal] = useState(false);
  const [selectedAudioId, setSelectedAudioId] = useState("");
  const [selectedAudioFile, setSelectedAudioFile] = useState("");
  const [audioPlay, setAudioPlay] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isShowResult, setIsShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const audioRefs = useRef([]);
  const audioPlayerRef = useRef(null); // Using useRef for the AudioPlayerBox
  const [audioToDelete, setAudioToDelete] = useState(null);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [userResponses, setUserResponses] = useState({});
  const [isShowResearchSelected, setIsShowResearchSelected] = useState(false);
  const [keywordCount, setKeywordCount] = useState(0);

  const closeModal = () => {
    setShowDeleteModal(false);

    setAudioToDelete(null);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchRecordings = async () => {
    try {
      const response = await fetchRecordingsService(
        currentUser.email,
        id,
        currentUser.access
      );
      if (response && Array.isArray(response)) {
        setAudioList(response);
        setSelectedAudioId(response[0]?.id);
        setSelectedAudioFile(`${API_URL}${response[0]?.recording_file}`);
      } else {
        console.error("Unexpected data format", response);
      }
    } catch (error) {
      console.error("Error fetching the recordings:", error);
    }
  };

  useEffect(() => {
    fetchRecordings();
  }, [id, currentUser]);

  const handleAudioSelection = (event) => {
    const selectedId = Number(event.target.value);
    setSelectedAudioId(selectedId);

    const selectedAudio = audioList.find((audio) => audio.id === selectedId);
    if (selectedAudio) {
      setSelectedAudioFile(`${API_URL}${selectedAudio.recording_file}`);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
    // setAudioToDelete(selectedAudioId);
  };

  const handleDelete = async (selectedAudioId) => {
    setAudioToDelete(selectedAudioId);
    console.log(selectedAudioId, "selectedAudioId");
    if (audioToDelete) {
      setShowDeleteModal(false);
      setShowModal(true);
    }
  };

  const handleConfirmDelete = async () => {
    if (audioToDelete) {
      try {
        await axios.post(
          REACT_APP_DELETE_RECORDING,
          { id: audioToDelete },
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          }
        );
        fetchRecordings();
        setShowModal(false);
        setShowDeleteModal(false);
        toast.success("Audio Recording Deleted");

        // const updatedList = audioList.filter(
        //   (audio) => audio.id !== audioToDelete
        // );
        // setAudioList(updatedList);
        // setShowModal(false);
        // setShowDeleteModal(false);
        // toast.success("Audio Recording Deleted");

        // if (audioToDelete === selectedAudioId) {
        //   setSelectedAudioId(updatedList.length > 0 ? updatedList[0].id : null);
        //   setSelectedAudioFile(
        //     updatedList.length > 0 ? `${API_URL}${updatedList[0].file}` : ""
        //   );
        // }
      } catch (error) {
        console.error("Error deleting the audio:", error);
      }
    }
  };

  const handleDeleteAll = () => {
    setShowDeleteAllModal(true); // Show confirmation modal for delete all
  };

  const handleConfirmDeleteAll = async () => {
    try {
      const response = await deleteAllRecording(
        id,
        currentUser.email,
        currentUser.access
      );
      if (response) {
        setShowDeleteModal(false);
        setShowDeleteAllModal(false);
        toast.success("Audio is deleted.");
        setAudioList([]);
      }
    } catch (error) {
      console.error("Error deleting the audio:", error);
    }
  };

  const audioToggle = async () => {
    // console.log("first-audioPlay", audioPlay);
    setAudioPlay(!audioPlay);
  };

  // const onClickSubmit = async () => {
  //   setIsShowResult(!isShowResult);
  // };

  // const areAllKeywordsEvaluated = () => {
  //   return keywords.every((keywordObj) => {
  //     const keyword = keywordObj.source_language;
  //     const response = userResponses[keyword];
  //     if (response === undefined) {
  //       console.warn(`No response for keyword: ${keyword}`);
  //     }
  //     return response !== undefined;
  //   });
  // };

  const areAllKeywordsEvaluated = () => {
    console.log("userResponses", userResponses);

    const missingResponses = [];

    // Loop through all keywords and collect missing responses
    keywords.forEach((keywordObj) => {
      const keyword = keywordObj.source_language;
      const response = userResponses[keyword];
      // console.log(keyword, response);
      if (response === undefined) {
        missingResponses.push(keyword);
      }
    });
    const missingKeywordsCount = missingResponses.length;
    // Assuming userResponses is an object
    if (keywordCount + missingKeywordsCount !== keywords.length) {
      return false;
    }
    // If there are missing responses, update userResponses state
    if (missingResponses.length > 0) {
      // Only set state once to prevent unnecessary re-renders
      // setUserResponses((prev) => {
      //   const updatedResponses = { ...prev };
      //   missingResponses.forEach((keyword) => {
      //     console.log("keyword", keyword);
      //     updatedResponses[keyword] = "right";
      //   });
      //   return updatedResponses;
      // });
      missingResponses.forEach((keyword) => {
        setKeywordCount(keywordCount + 1);
        setUserResponses((prev) => ({ ...prev, [keyword]: "right" }));
      });
      console.log("userResponses", userResponses);

      // Log a warning for missing responses
      missingResponses.forEach((keyword) => {
        console.warn(`No response for keyword: ${keyword}`);
      });

      // return false; // Return false if there are missing responses
    }

    // Return true if all keywords have responses
    return true;
  };

  const onClickSubmit = () => {
    if (!areAllKeywordsEvaluated()) {
      // toast.error("Please ensure all keywords are evaluated correctly.");
      setReserchShowModal(true);
      return;
    }
    try {
      const isResearchSelected = Object.values(userResponses).some(
        (response) => response === "research"
      );

      if (isResearchSelected) {
        setIsShowResearchSelected(true);
      } else {
        setIsShowResult(true);
      }
    } catch (error) {
      console.error("Error submitting results:", error);
      toast.error("Failed to submit results.");
    }
  };

  // Filter only the keywords marked for "research"
  const researchKeywords = keywords.filter(
    (keywordObj) => userResponses[keywordObj.source_language] === "research"
  );
  const handleSubmit = () => {
    setIsShowResult(false);
    setIsShowResearchSelected(false);
    setKeywordCount(0);
    setUserResponses({});
  };

  useEffect(() => {
    const fetchTranscriptText = async () => {
      try {
        const data = await fetchTranscriptService(id, currentUser.access);
        if (data) {
          setKeywords(data.keyword.keywords_list);
          setTranscriptText(data.transcript.transcript);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) =>
      paragraph
        .replace(/<break \/>/g, "\n \n")
        .replace(/<break\/>/g, "\n \n")
        .replace(/\*/g, "")
    )
    .join("\n\n");

  const openEvaluteNow = () => {
    navigate(`/audio-recording-player/${id}`);
  };

  const backClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (audioPlayerRef.current) {
        const sticky = audioPlayerRef.current.offsetTop;

        if (window.pageYOffset > sticky) {
          setIsSticky(true); // Add sticky class when scrolled past
        } else {
          setIsSticky(false); // Remove sticky class
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Callback to track keyword count
  const handleKeywordCount = (count) => {
    setKeywordCount(count); // Store the total count of unique keywords
  };

  return (
    <div className="min-screen recordingContent">
      <HeaderWhiteComponent className="headerr" />
      <div
        className="recording-with-key-main-div"
        style={{
          backgroundColor:
            isShowResult || isShowResearchSelected ? "" : "white",
          width: "100% ",
        }}
      >
        <div className="recording-with-key-mainContentBox">
          <div className="recordingKeywordBox">
            <div className="RecordingKeywordContent">
              {isShowResult ? (
                <Research
                  keywords={keywords}
                  userResponses={userResponses}
                  setUserResponses={setUserResponses}
                  onSubmit={handleSubmit}
                />
              ) : isShowResearchSelected ? (
                <ResearchSelected
                  keywords={researchKeywords}
                  setUserResponses={setUserResponses}
                  onClickSubmit={onClickSubmit}
                />
              ) : (
                <>
                  <div className="topBarcontent">
                    <div className="topBarcontentRight">
                      <div className="userName">
                        <button className="btn btn-primary" onClick={backClick}>
                          <img
                            className="back-dash-right"
                            src={images.ArrowLeftwhite}
                            alt="Back"
                          />
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="keyword-btn-div">
                      <div className="topBarcontentRight">
                        {audioList.length > 0 ? (
                          <div className="userName">
                            <button
                              className="btn btn-primary"
                              // onClick={() => handleDeleteClick()}
                              onClick={handleDeleteClick}
                            >
                              Delete recording(s)
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="RecordingMidContent">
                    <div className="midLeft">
                      <img
                        src={images.bigLogo}
                        alt="logo"
                        className="logoImg"
                      />
                    </div>

                    {audioList.length > 0 ? (
                      <div className="midRight custom-select-wrapper">
                        <select
                          className="form-control custom-select mb-3"
                          value={selectedAudioId}
                          onChange={handleAudioSelection}
                        >
                          <option value="">-- select recording --</option>
                          {audioList.map((audio) => (
                            <option key={audio.id} value={audio.id}>
                              {audio.recording_name}
                            </option>
                          ))}
                        </select>

                        <div
                          id="audioPlayerBox"
                          ref={audioPlayerRef} // Assign ref to AudioPlayerBox
                          className={`audio-item ${
                            isSticky ? "audioPlayerSticky" : ""
                          }`} // Toggle sticky class
                          style={{ padding: "0" }}
                        >
                          <div className="AudioPlayerBox">
                            {selectedAudioFile && (
                              <AudioPlayer
                                index={selectedAudioId}
                                audioData={audioList}
                                REACT_APP_URL={REACT_APP_URL}
                                audioRefs={audioRefs}
                                src={selectedAudioFile}
                                play={audioPlay}
                                playStatus={audioToggle}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="midRight">
                        <p>No recording Available </p>
                        {/* <button onClick={openEvaluteNow}>Evaluate Now</button> */}
                      </div>
                    )}
                  </div>

                  <div className="recordingDescription">
                    {formattedTranscript && keywords && (
                      <TranscriptText
                        text={formattedTranscript}
                        keywords={keywords}
                        callbackForAudio={audioToggle}
                        userResponses={userResponses} // Pass userResponses for each keyword
                        setUserResponses={setUserResponses}
                        callbackForKeywords={handleKeywordCount} // This will track the keyword count
                      />
                    )}
                    <br />
                    <b>END OF PRACTICE.</b>

                    <div className="record-with-keyword-submit">
                      <button
                        className="btn btn-primary"
                        onClick={onClickSubmit}
                      >
                        submit
                      </button>
                    </div>
                  </div>
                </>
              )}

              <Footer />
              <DeleteListModal
                show={showDeleteModal}
                handleClose={closeModal}
                recordings={audioList}
                handleDelete={handleDelete}
                handleDeleteAll={handleDeleteAll}
              />
              {showModal && (
                <ConfirmDeleteModal
                  showModal={showModal}
                  onClose={handleCloseModal}
                  onDelete={handleConfirmDelete}
                  message={
                    "After deleting this recording, it will be removed everywhere, and nobody will be able to access it."
                  }
                />
              )}
              {showDeleteAllModal && (
                <ConfirmDeleteModal
                  showModal={showDeleteAllModal}
                  onClose={() => setShowDeleteAllModal(false)}
                  onDelete={handleConfirmDeleteAll}
                  message={
                    "Are you sure you want to delete all recordings? This action cannot be undone."
                  }
                />
              )}
              {reasearchshowModal && (
                <ResearchModal
                  show={reasearchshowModal}
                  onClose={() => setReserchShowModal(false)}
                  message="You have missed some keywords."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingWithKeyword;
