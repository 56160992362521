import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginScreen from "../pages/Login/Login";
import SignUpScreen from "../pages/SignUp/SignUpPage";
import WelcomePage from "../pages/Welcome/WelcomePage";
import VocabularyScreen from "../pages/VocablaryPage/VocabPageOne/VocablaryPage";
import SelectLanguage from "../pages/VocablaryPage/SelectLanguage/Selectlanguage";
import VocablaryTopic from "../pages/VocablaryPage/Topic/Topic";
import VocablaryLanguageLevel from "../pages/VocablaryPage/LanguageLevel/LanguageLevel";
import GeographicalArea from "../pages/VocablaryPage/GeographicalArea/GeographicalArea";
import TargetLanguageError from "../pages/VocablaryPage/VocabError/VocabError";
import EditVocabList from "../pages/VocablaryPage/VocabList/EditVocabList";
import GeneratedVocablary from "../pages/VocablaryPage/GeneratedVocablry/Generatedvocablary";
import Congratulation from "../pages/VocablaryPage/Congratulations/Congratulation";
import PracticeDashboard from "../pages/Dashboard/Dashboard";
import TranscriptGeneration from "../pages/TranscriptGenerPage/TranscriptLength/TranscriptLength";
import TranscriptSpeaker from "../pages/TranscriptGenerPage/TranscriptSpeaker/TranscriptSpeaker";
import TranscriptAdditionalInfo from "../pages/TranscriptGenerPage/TranscriptAddition/TranscriptAdditonal";
import TranscriptGenerated from "../pages/TranscriptGenerPage/TransGeneratedPage/TransGeneratedPage";
import GeneratePractise from "../pages/VocablaryPage/GeneratePractise/GeneratePractise";
import RequireAuth from "../components/Auth/RequireAuth";
import VoiceCatalog from "../pages/VoiceGeneration/VoiceList/VoiceCatlog";
import TranscriptVoice from "../pages/VoiceGeneration/VoiceGenerate/VoiceGenerate";
import VoiceCongratulation from "../pages/VoiceGeneration/Congratulations/Congratulation";
import AudioPlayer from "../pages/VoiceGeneration/AudioPlayer/Player";
import GameQuestionAndFlashCard from "../pages/GameQuestionAndFlashCard/GameQuestionAndFlashCard";
import EditVocabulary from "../pages/VocablaryPage/Editnew/Edit";
import EditVocabularyKeywords from "../pages/VocablaryPage/Editnew/EditVocabulary";
import EditTranscript from "../pages/TranscriptGenerPage/Edit/Edit";
import EditTranscriptGenerated from "../pages/TranscriptGenerPage/Edit/EditTranscriptGenerated";
import Recording from "../pages/Recording/Recording";
import TranscriptPage from "../pages/TranscriptGenerPage/TranscriptsPage/TranscriptPage";
import AudioGenerated from "../pages/VoiceGeneration/AudioGenerated/AudioGenerated";
import RecordingWithKeyword from "../pages/RecordingWithKeyword/RecordingWithKeyword";
import PracticeShare from "../pages/PracticeShare/PracticeShare";
import AudioRecordingPlayer from "../pages/AudioRecording/AudioRecording";
import UpdateProfile from "../pages/UpdateProfile/UpdateProfile";
import AudioRecordPlayer from "../pages/AudioRecordingWithRecorder/AudioRecorder";
import ForgotScreen from "../pages/ForgotPassword/Forgot";
import ForgotPasswordScreen from "../pages/ForgotPassword/ResetPassword/ResetPassword";
import ResetPassword from "../pages/ForgotPassword/ResetPassword/ResetPassword";
import ViewRecordingWithKeyword from "../pages/ViewRecordingWithId/ViewRecordingWithKeyword";
import TranscriptType from "../pages/TranscriptGenerPage/TranscriptType/TranscriptType";
import CommonUseCases from "../pages/CommanUseCase/CommanCase";
// import AudioRecordPlayerTesting from "../pages/AudioRecordingWithRecorder/AudioRecorder";
function IndexRoutes() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LoginScreen />} />
        <Route
          path="/login"
          element={
            <RequireAuth redirectTo="/login">
              <LoginScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/signup"
          element={
            <RequireAuth redirectTo="/practice-dashboard">
              <SignUpScreen />
            </RequireAuth>
          }
        />
        {/* Protected Routes */}
        <Route
          path="/welcome"
          element={
            <RequireAuth redirectTo="/login">
              <WelcomePage />
            </RequireAuth>
          }
        />
        <Route
          path="/practice-dashboard"
          element={
            <RequireAuth redirectTo="/login">
              <PracticeDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/vocabulary-screen"
          element={
            <RequireAuth redirectTo="/login">
              <VocabularyScreen />
            </RequireAuth>
          }
        />
        <Route
          path="/select-language"
          element={
            <RequireAuth redirectTo="/login">
              <SelectLanguage />
            </RequireAuth>
          }
        />
        <Route
          path="/vocabulary-topic"
          element={
            <RequireAuth redirectTo="/login">
              <VocablaryTopic />
            </RequireAuth>
          }
        />
        <Route
          path="/vocabulary-language-level"
          element={
            <RequireAuth redirectTo="/login">
              <VocablaryLanguageLevel />
            </RequireAuth>
          }
        />
        <Route
          path="/geographical-area"
          element={
            <RequireAuth redirectTo="/login">
              <GeographicalArea />
            </RequireAuth>
          }
        />
        <Route
          path="/TargetLanguageError"
          element={
            <RequireAuth redirectTo="/login">
              <TargetLanguageError />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-vocab-list"
          element={
            <RequireAuth redirectTo="/login">
              <EditVocabList />
            </RequireAuth>
          }
        />
        <Route
          path="/generated-vocabulary"
          element={
            <RequireAuth redirectTo="/login">
              <GeneratedVocablary />
            </RequireAuth>
          }
        />
        <Route
          path="/congratulation/:id"
          element={
            <RequireAuth redirectTo="/login">
              <Congratulation />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-generation/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptGeneration />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-type/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptType />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-speaker/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptSpeaker />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-additional-info/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptAdditionalInfo />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-generated/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptGenerated />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/GeneratePractise"
          element={
            <RequireAuth redirectTo="/login">
              <GeneratePractise />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/voice-catalog/:id"
          element={
            <RequireAuth redirectTo="/login">
              <VoiceCatalog />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-voice/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptVoice />
            </RequireAuth>
          }
        />
        <Route
          path="/voice-congratulation/:id"
          element={
            <RequireAuth redirectTo="/login">
              <VoiceCongratulation />
            </RequireAuth>
          }
        />
        <Route
          path="/audio-player/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AudioPlayer />
            </RequireAuth>
          }
        />
        <Route
          path="/game-question-and-flash-card/:id"
          element={
            <RequireAuth redirectTo="/login">
              <GameQuestionAndFlashCard />
            </RequireAuth>
          }
        />
        <Route
          path="/vocabulary/:id"
          element={
            <RequireAuth redirectTo="/login">
              <EditVocabulary />
            </RequireAuth>
          }
        />
        <Route
          path="/vocabulary/keywords/:id"
          element={
            <RequireAuth redirectTo="/login">
              <EditVocabularyKeywords />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript/:id"
          element={
            <RequireAuth redirectTo="/login">
              <EditTranscript />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript/generated/:id"
          element={
            <RequireAuth redirectTo="/login">
              <EditTranscriptGenerated />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript/view/:id"
          element={
            <RequireAuth redirectTo="/login">
              <TranscriptPage />
            </RequireAuth>
          }
        />
        <Route
          path="/transcript-with-audio/view/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AudioGenerated />
            </RequireAuth>
          }
        />
        <Route
          path="/recording/:id"
          element={
            <RequireAuth redirectTo="/login">
              <Recording />
            </RequireAuth>
          }
        />
        <Route
          path="/recording-with-keyword/:id"
          element={
            <RequireAuth redirectTo="/login">
              <RecordingWithKeyword />
            </RequireAuth>
          }
        />
        <Route
          path="/practice-share-link/:id"
          element={
            <RequireAuth redirectTo="/login">
              <PracticeShare />
            </RequireAuth>
          }
        />
        <Route
          path="/audio-recording-player/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AudioRecordingPlayer />
            </RequireAuth>
          }
        />
        <Route
          path="/update-profile"
          element={
            <RequireAuth redirectTo="/login">
              <UpdateProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/audio-interpret/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AudioRecordPlayer />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/audio-interpret-testing/:id"
          element={
            <RequireAuth redirectTo="/login">
              <AudioRecordPlayerTesting />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/view-recording-with-keyword/:id/:audioId"
          element={
            <RequireAuth redirectTo="/login">
              <ViewRecordingWithKeyword />
            </RequireAuth>
          }
        />
        <Route
          path="/comman-use-case"
          element={
            <RequireAuth redirectTo="/login">
              <CommonUseCases />
            </RequireAuth>
          }
        />
        <Route path="/forgot-password" element={<ForgotScreen />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        CommonUseCases
      </Routes>
    </Router>
  );
}

export default IndexRoutes;
