import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";

import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import "./AudioRecorder.css";
import images from "../../images";
import logo from "../../assets/Images/logo-big.png";
import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";
import AudioSaveModal from "../../components/Modal/AudioSave/AudioSave";
import EvaluateModal from "../../components/Modal/EvaluateModel/EvaluateModal";
import AudioCancelModal from "../../components/Modal/AudioCancle/AudioCancle";
import { fetchTranscriptService } from "../../services/transcript.service";
import { submitRecordingService } from "../../services/audio.service";
import { UserContext } from "../../providers/UserContext";
import { API_URL } from "../../constants";
import Overlay from "../../components/Timer/Overlay/Overlay";
import AudioPlayModal from "../../components/Modal/AudioPlay/AudioPlay";
import GlowingCountdown from "../../components/GlowingCountdown/GlowingCountdown";
import MicrophoneChecker from "../../components/MicrophoneChecker/MicrophoneChecker";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
function AudioRecordPlayer() {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPlayOpen, setIsModalPlayOpen] = useState(false);

  const [isEvaluateModalOpen, setIsEvaluateModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [audioFile, setAudioFile] = useState();
  const [countdown, setCountdown] = useState(0); // For countdown
  const [firstPlay, setFirstPlay] = useState(true); // To track the first play
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [isPaused, setIsPaused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blob, setBlob] = useState();
  // const [isWaveReady, setIsWaveReady] = useState(false);
  const { currentUser } = useContext(UserContext);
  const containerRef = useRef(null);
  const countdownInterval = useRef(null);
  const navigate = useNavigate();
  const [playButtonVisible, setPlayButtonVisible] = useState(false);
  const { wavesurfer } = useWavesurfer({
    container: containerRef,
    waveColor: "#ccc",
    progressColor: "#007bff",
    height: 80,
    barWidth: 1,
    responsive: false,
    barGap: 1,
    minPxPerSec: 10,
    normalize: true,
    url: audioUrl,
    // plugins: useMemo(() => [Timeline.create()], []),
    // volume: 0, // Start muted
  });

  useEffect(() => {
    const fetchTranscriptText = async () => {
      setLoading(true);
      try {
        const transcriptData = await fetchTranscriptService(
          id,
          currentUser.access
        );
        if (transcriptData) {
          const audios = transcriptData.save_audios || [];
          const audio = audios.find((item) => item.audio_file_path != null);
          if (audio) {
            const url = `${API_URL}/media/${audio.audio_file_path}`;
            setAudioUrl(url);

            setIsModalPlayOpen(true);
            console.log("Audio URL set:", url);
          } else {
            console.error("No valid audio file found");
          }
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      } finally {
        setLoading(false);
      }
    };
    if (currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  // const onPlayPause = useCallback(() => {
  //   wavesurfer && wavesurfer.playPause();
  // }, [wavesurfer]);

  const onPlayPause = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.playPause();
      setPlaying(wavesurfer.isPlaying());
      setIsPaused(!wavesurfer.isPlaying());
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (wavesurfer && audioUrl) {
      wavesurfer.load(audioUrl);
    }
  }, [wavesurfer, audioUrl]);

  // const onStart = () => {
  //   setIsModalPlayOpen(false);
  //   const initialCountdown = 5; // Countdown duration in seconds
  //   setCountdown(initialCountdown);

  //   countdownInterval.current = setInterval(() => {
  //     setCountdown((prevCountdown) => {
  //       if (prevCountdown <= 1) {
  //         clearInterval(countdownInterval.current);
  //         onPlayPause();
  //         setPlaying(true);
  //         startRecording();
  //         setFirstPlay(false); // Reset firstPlay to avoid future countdowns
  //         return 0;
  //       }
  //       return prevCountdown - 1;
  //     });
  //   }, 1000);
  // };
  const handlePlayButtonClick = () => {
    setPlayButtonVisible(false); // Hide the play button once clicked
    onPlayPause(); // Start playing
  };
  const onStart = () => {
    setIsModalPlayOpen(false);
    const initialCountdown = 5; // Countdown duration in seconds
    setCountdown(initialCountdown);

    countdownInterval.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval.current);
          console.log("Attempting to start audio playback...");
          onPlayPause();
          setPlaying(true);
          startRecording();
          setFirstPlay(false);

          // Check if audio playback started immediately after onPlayPause
          if (wavesurfer && !wavesurfer.isPlaying()) {
            console.log("Initial playback failed.");
            setPlayButtonVisible(true);

            // toast.info(
            //   "Autoplay permission is disabled in your browser. Please manually play the audio by clicking the Play icon."
            // );
            toast.info(
              "Autoplay is currently disabled in your web browser. Please click the play icon to start your audio manually.",
              {
                // className: "toast-share-center",
                className: "custom-toast",
                bodyClassName: "custom-toast-body",
                autoClose: false,
                position: "top-center",

                // icon: (
                //   <img
                //     src={images.Partyicon}
                //     alt="Custom Icon"
                //     style={{ width: 24, height: 24 }}
                //   />
                // ),
                // icon: false,
              }
            );
          } else {
            console.log("Audio playback started successfully.");
          }

          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleTogglePauseResume = () => {
    onPlayPause();
    togglePauseResume();
    setIsPaused(!isPaused);
    // setIsPaused((prev) => !prev);
  };

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    mediaRecorder,
  } = useAudioRecorder();

  const handleModalClose = () => {
    setIsModalOpen(false);
    window.location.reload();
  };
  const getFileExtension = (mimeType) => {
    switch (mimeType) {
      case "audio/webm":
        return "webm";
      case "audio/mp3":
        return "mp3";
      case "audio/wav":
        return "wav";
      default:
        return "audio"; // fallback in case of an unrecognized MIME type
    }
  };

  useEffect(() => {
    if (!recordingBlob) return;

    const extension = getFileExtension(recordingBlob.type); // Get the extension based on MIME type
    const fileName = `${id}_audioRecording.${extension}`; // Use the dynamic extension in file name
    const audioFile = blobToFile(recordingBlob, fileName);
    onPlayPause();
    setAudioFile(audioFile);
    setIsModalOpen(true);
  }, [recordingBlob, id]);

  useEffect(() => {
    if (!blob) return;

    const extension = getFileExtension(blob.type);
    const fileName = `${id}_audioRecording.${extension}`;
    const audioFile = blobToFile(blob, fileName);
    console.log("Audio File:", audioFile);
    setAudioFile(audioFile);
    setIsModalOpen(true);
    onPlayPause();
  }, [blob, id]);

  const blobToFile = (blob, fileName) => {
    console.log("blob", blob);
    return new File([blob], fileName, { type: blob.type });
  };

  const submitAudio = async (data) => {
    const email = currentUser.email;
    const accessToken = currentUser.access;

    const response = await submitRecordingService(
      id,
      audioFile,
      data.audioName,
      data.removeSilence,
      email,
      accessToken
    );

    setIsModalOpen(false);
    setIsEvaluateModalOpen(true);
  };

  const goToRecordingWithKeyword = () => {
    navigate(`/recording-with-keyword/${id}`);
  };

  const evaluateModalClose = () => {
    toast.success("Your recording has been successfully saved.");
    navigate("/practice-dashboard");
    setIsEvaluateModalOpen(false);
  };
  const handleCancelModalOpen = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  const handleCancelConfirm = () => {
    setIsCancelModalOpen(false);
  };
  const backClick = () => {
    onPlayPause();
    navigate(-1); // Navigate back
  };
  const handlemodaldash = () => {
    navigate("/practice-dashboard");
  };
  return (
    <div className="player-container">
      <HeaderWhiteComponent className="headerr" />

      <div className="mainContentBox">
        <div className="topBarcontentRight-player">
          <div className="userName">
            <button className="btn btn-primary" onClick={backClick}>
              Back
            </button>
          </div>
        </div>
        <div className="record-player">
          {loading ? (
            <Loader />
          ) : (
            <div className="player-player-content">
              <div className="icon-container">
                <img
                  src={logo}
                  alt="logo"
                  className="logoImg"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="waveform-container">
                {/* {playing ? */}
                {/* <div ref={waveformRef} className="waveform"></div> */}
                <div ref={containerRef} className="waveform" />
                {/* {!isWaveReady && <p>Loading waveform...</p>} */}
              </div>
              <AudioPlayModal
                isOpen={isModalPlayOpen}
                onClose={handlemodaldash}
                onStart={onStart}
              />
              <Overlay isVisible={countdown > 0}>
                <GlowingCountdown />
              </Overlay>
            </div>
          )}
        </div>

        {countdown === 0 && (
          <div className="footer-audio-recording">
            <div className="audio-recorder-controller">
              {mediaRecorder && (
                <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder}
                  width={300}
                  height={60}
                />
              )}
            </div>

            <div className="audio-recording-player">
              {!isRecording ? (
                isModalPlayOpen ? (
                  <MicrophoneChecker />
                ) : (
                  ""
                )
              ) : (
                <div className="recording-controls">
                  <div className="audioControlbtn">
                    <button className="control-btn" onClick={stopRecording}>
                      <img src={images.stop} alt="Stop and Save" />
                      <p>Stop & Save</p>
                    </button>
                    {playButtonVisible && (
                      <button
                        className="control-btn"
                        onClick={handlePlayButtonClick}
                      >
                        <img src={images.Playbutton} alt="Play" />
                        <p>Play</p>
                      </button>
                    )}

                    {!playButtonVisible && (
                      <button
                        className="control-btn"
                        onClick={handleTogglePauseResume}
                      >
                        <img
                          src={images.pause}
                          alt={isPaused ? "Resume" : "Pause"}
                        />
                        <p>{isPaused ? "Resume" : "Pause"}</p>

                        {isRecording && !isPaused && (
                          <div id="record-on" className="blink-recording"></div>
                        )}
                      </button>
                    )}
                    <button
                      className="control-btn"
                      onClick={handleCancelModalOpen}
                    >
                      <img src={images.cancle} alt="Cancel" />
                      <p>Cancel</p>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <AudioSaveModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={(data) => submitAudio(data)}
      />

      <EvaluateModal
        isOpen={isEvaluateModalOpen}
        onClose={evaluateModalClose}
        onNowClick={goToRecordingWithKeyword}
      />
      <AudioCancelModal
        show={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleConfirm={handleCancelConfirm}
      />
    </div>
  );
}

export default AudioRecordPlayer;
