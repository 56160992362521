import React from 'react';
import './VocabModal.css'; 
import images from '../../../images'; 

const ModalComponent = ({ modalText }) => {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <img src={images.VocabModal} alt="Modal Visual" className="modal-image" />
                <p className="modal-text">{modalText}</p>
            </div>
        </div>
    );
};

export default ModalComponent;
