import React, { useState } from "react";
import images from "../../images";
import SourceLanguageInput from "./SourceLanguageInput/SourceLanguageInput";

export const VocabularyCard = ({
  item,
  index,
  highlightedCardIndex,
  focusedKeywordIndex,
  handleKeywordSaveClick,
  setFocusedKeywordIndex,
  handlePrivAndNextHistory,
  handleRegenerateOneClick,
  handleDeleteClick,
  cardRefs,
  displayedList,
}) => {
  // Local state for editable fields
  const [editableKeyword, setEditableKeyword] = useState(item.source_language);
  const [editableTranslation, setEditableTranslation] = useState(
    item.target_language
  );

  // Handle input changes locally
  const handleInputChange = (field, value) => {
    if (field === "source_language") {
      setEditableKeyword(value);
    } else if (field === "target_language") {
      setEditableTranslation(value);
    }
  };

  // Save changes to parent
  const handleSaveClick = () => {
    handleKeywordSaveClick(index, {
      source_language: editableKeyword,
      target_language: editableTranslation,
    });
    setFocusedKeywordIndex(null);
  };

  return (
    <div
      className={`card ${index === highlightedCardIndex ? "highlighted" : ""}`}
      ref={(el) => (cardRefs.current[index] = el)}
    >
      <div className="card-body">
        <div className="input-box">
          <label className="form-label">Keyword</label>
          {/* <input
            type="text"
            className="form-control input-center"
            value={editableKeyword}
            readOnly={index !== focusedKeywordIndex}
            onChange={(e) =>
              handleInputChange("source_language", e.target.value)
            }
          /> */}
          <SourceLanguageInput
            value={editableKeyword}
            readOnly={index !== focusedKeywordIndex}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-box">
          <label className="form-label">Language Equivalents</label>
          <textarea
            rows="3"
            cols="50"
            className="form-control"
            value={editableTranslation}
            readOnly={index !== focusedKeywordIndex}
            onChange={(e) =>
              handleInputChange("target_language", e.target.value)
            }
          ></textarea>
        </div>

        <div className="btn-list">
          {index === focusedKeywordIndex ? (
            <>
              <button className="btn btn-success" onClick={handleSaveClick}>
                Save
              </button>
              <button
                className="btn btn-danger"
                onClick={() => setFocusedKeywordIndex(null)}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-success"
                onClick={() => setFocusedKeywordIndex(index)}
              >
                Edit
              </button>

              <div className="regenratebutton">
                {item.prev && (
                  <button
                    className="btn btn-secondary roundbtn"
                    onClick={() =>
                      handlePrivAndNextHistory(displayedList, "previous")
                    }
                  >
                    <img src={images.ArrowLeft} alt="ArrowLeft" />
                    <span className="tooltip">Previous Vocabulary</span>
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => handleRegenerateOneClick(index)}
                >
                  Regenerate
                </button>
                {item.next && (
                  <button
                    className="btn btn-secondary roundbtn"
                    onClick={() =>
                      handlePrivAndNextHistory(displayedList, "next")
                    }
                  >
                    <span className="tooltip">Next Vocabulary</span>
                    <img src={images.ArrowRight} alt="ArrowRight" />
                  </button>
                )}
              </div>

              <button
                className="btn btn-danger"
                onClick={() => handleDeleteClick(index)}
              >
                Delete
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
