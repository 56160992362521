import React from 'react';
import { Link } from 'react-router-dom';
import HeaderComponent from 'components/Header/Header';
import Button from 'components/Button/SignupButton';

const TargetLanguageError = () => {
    return (
        <div className="min-screen screen8">
            <HeaderComponent />
            <div className="main-content">
                <h1 className="title">The system was unable to generate a complete vocabulary list in the target language. If you want to continue, please select one of the options below:</h1>
                <div className="options">
                    <h4 className="title">Option 1: Generate Practice in English (No Flashcards)</h4>
                    <p className="des">
                        Click this button to receive a customized practice session in English on your requested topic. This practice will be generated without the use of flashcards, ensuring a focus on direct learning and application.
                    </p>
                    <button className="continuebtn">Continue</button>
                </div>
                <div className="options">
                    <h4 className="title">Option 2: Edit Manually</h4>
                    <p className="des">
                        Choose this option to research and manually enter the English terms in another language. This allows you to tailor your learning experience by adding terms and details that are most relevant to your needs.
                    </p>
                    <button className="continuebtn">Continue</button>
                </div>
                <div className="btn-list">
                    <Link to="/GeographicalArea" className='noDecoration'>
                        <Button className="small">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="currentColor"
                            >
                                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                            </svg>
                            Back
                        </Button>
                    </Link>
                    <Link to="/EditVocabList" className='noDecoration'>
                        <Button className="small">
                            next
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="currentColor"
                            >
                                <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                            </svg>
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="Copyright">
                <p>Copyright © Interpretrain, All rights reserved 2024.</p>
            </div>
        </div>
    );
};

export default TargetLanguageError;
