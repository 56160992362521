import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import { UserContext } from "../../../providers/UserContext";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";
import { generateVocabulary } from "../../../services/vocab.service";
import { useDispatch } from "react-redux";
import { setVocabList } from "../../../redux/vocabSlice";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";

const VocablaryLanguageLevel = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [level, setlevel] = useState(formData.difficulty || "");
  const [error, setError] = useState("");
  const [showErorModal, setShowErosModal] = useState(false);
  const [region, setRegion] = useState(formData.region || "US");
  const [showModal, setShowModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDifficultyChange = (e) => {
    setlevel(e.target.value);
    updateFormData("level", e.target.value);
    updateFormData("region", region);

    if (e.target.value) {
      setError("");
    }
  };
  const handleNextClick = async (e) => {
    if (!level) {
      e.preventDefault();
      setError("Please select a difficulty level.");
      toast.error("Please select a difficulty level.");
      return;
    }

    setShowErosModal(false); // Initially hide error modal
    setShowModal(true); // Show loading modal
    setApiError(""); // Reset any previous error

    const maxRetries = 2;
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
      try {
        const data = await generateVocabulary(
          currentUser.access,
          formData,
          region,
          "",
          REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
        );

        if (
          data &&
          data.keywords &&
          data.keywords.keywords &&
          Array.isArray(data.keywords.keywords.lists)
        ) {
          console.log(
            "data.keywords.keywords.lists",
            data.keywords.keywords.lists[0].length
          );
          const keywords = [
            {
              prev: false,
              keyword: data.keywords.keywords.lists[0],
              next: false,
            },
          ];
          dispatch(setVocabList(keywords));
          // dispatch(setVocabList(data.keywords.keywords.lists));
          success = true;
          toast.success("Vocabulary Generated Successfully");
          navigate("/edit-vocab-list");
          break; // Exit while loop if successful
        } else {
          console.error("Invalid data format", data);
          break; // Exit the loop if data format is invalid
        }
      } catch (error) {
        console.error(
          `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
          error
        );
        attempts += 1;

        // If the maximum retry attempts are reached
        if (attempts >= maxRetries) {
          setApiError(
            "Error in generating vocabulary. Please try again later."
          );
          setShowModal(false); // Hide the loading modal
          setShowErosModal(true); // Show the error modal
          toast.error(
            "Error in generating vocabulary. Please try again later."
          );
        } else {
          console.log("Retrying the API call...");
        }
      }
    }
  };

  // const handleNextClick = async (e) => {
  //   if (!level) {
  //     e.preventDefault();
  //     setError("Please select a difficulty level.");
  //     toast.error("Please select a difficulty level.");
  //     return;
  //   }
  //   setShowErosModal(false);
  //   // const token = localStorage.getItem("accessToken");

  //   // console.log("usertoken", currentUser.access)
  //   setShowModal(true);
  //   setApiError("");

  //   const maxRetries = 2;
  //   let attempts = 0;
  //   let success = false;

  //   while (attempts < maxRetries && !success) {
  //     try {
  //       const data = await generateVocabulary(
  //         currentUser.access,
  //         formData,
  //         region,
  //         "",
  //         REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
  //       );

  //       if (
  //         data &&
  //         data.keywords &&
  //         data.keywords.keywords &&
  //         Array.isArray(data.keywords.keywords.lists)
  //       ) {
  //         console.log(
  //           "data.keywords.keywords.lists",
  //           data.keywords.keywords.lists[0].length
  //         );
  //         dispatch(setVocabList(data.keywords.keywords.lists));
  //         success = true;
  //         toast.success("Vocabulary generated Successfully");
  //         navigate("/edit-vocab-list");
  //         break;
  //       } else {
  //         setShowModal(false);

  //         setShowErosModal(true);
  //         console.error("Invalid data format", data);
  //       }
  //       break;
  //     } catch (error) {
  //       setShowModal(false);
  //       setShowErosModal(true);
  //       console.error(
  //         `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
  //         error
  //       );
  //       attempts += 1;

  //       if (attempts >= maxRetries) {
  //         setApiError(
  //           "Error in Generating. Go back to Dashboard and please try again later."
  //         );
  //         setShowModal(false);

  //         setShowErosModal(true);
  //         toast.error(
  //           "Error in Generating. Go back to Dashboard and please try again later."
  //         );
  //         setShowModal(false);
  //       } else {
  //         console.log("Retrying the API call...");
  //       }
  //     }
  //   }
  // };
  const closeModal = () => {
    // handleNextClick();
    setShowErosModal(false);
  };
  return (
    <div className="min-screen screen6">
      {showModal && (
        <ModalComponent modalText="Generating vocabulary. Please wait." />
      )}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            How challenging should it be? Please select from the provided
            difficulty levels to ensure the vocabulary list matches your current
            language proficiency and learning goals.
          </p>
          <form className="form_">
            <label className="form-label mb-4">
              How challenging should it be?{" "}
              {/* <span className="text-danger">*</span> */}
            </label>
            <div className="input-box">
              <label className="custom-check form-label">
                Beginner
                <input
                  type="radio"
                  name="level"
                  value="beginner"
                  checked={level === "beginner"}
                  onChange={handleDifficultyChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="input-box">
              <label className="custom-check form-label">
                Intermediate
                <input
                  type="radio"
                  name="level"
                  value="intermediate"
                  checked={level === "intermediate"}
                  onChange={handleDifficultyChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="input-box">
              <label className="custom-check form-label">
                Advanced
                <input
                  type="radio"
                  name="level"
                  value="advanced"
                  checked={level === "advanced"}
                  onChange={handleDifficultyChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {error && (
              <p className="languagelevel-error-message-dark">{error}</p>
            )}
          </form>
          <div className="btn-list" style={{ marginTop: "-10px" }}>
            <Link to="/vocabulary-topic" className="noDecoration">
              <Button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </Button>
            </Link>
            <Link
              // to="/geographical-area"
              className="noDecoration"
              onClick={handleNextClick}
            >
              <Button className="small">
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNextClick}
        />
      )}
    </div>
  );
};

export default VocablaryLanguageLevel;
