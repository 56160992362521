
import { configureStore } from '@reduxjs/toolkit';
import vocabReducer from './vocabSlice';
import { loadState, saveState } from './localStorage';

// Load persisted state from localStorage
const persistedState = loadState();

const store = configureStore({
  reducer: {
    vocab: vocabReducer,
  },
  preloadedState: persistedState, // Set the preloaded state to the persisted state
});

// Subscribe to store changes and save the state to localStorage
store.subscribe(() => {
  saveState({
    vocab: store.getState().vocab
  });
});

export default store;
