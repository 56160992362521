
import { createSlice } from '@reduxjs/toolkit';

const vocabSlice = createSlice({
  name: 'vocab',
  initialState: {
    vocabList: [],
    tempVocabulary: [],
    currentListIndex: 0,
    focusedKeyword: null,
    showModal: false,
    transcriptText: "",
    transcriptHistory: [],
    tempTranscript: [],
    currentTranscriptIndex: 0,
    currentIdentifier: null,
  },
  reducers: {
    setVocabList(state, action) {
      state.vocabList = action.payload;
    },

    clearVocabState(state) {
      state.vocabList = [];
      state.tempVocabulary = [];
      state.currentListIndex = 0;
      state.focusedKeyword = null;
      state.showModal = false;
    },

    setTempVocabulary(state, action) {
      state.tempVocabulary = action.payload;
    },

    clearTempVocabulary(state) {
      state.tempVocabulary = [];
    },

    setCurrentListIndex(state, action) {
      state.currentListIndex = action.payload;
    },

    setFocusedKeyword(state, action) {
      state.focusedKeyword = action.payload;
    },

    setShowModal(state, action) {
      state.showModal = action.payload.showModal;
      state.keywordToDelete = action.payload.keywordToDelete || state.keywordToDelete;
    },

    deleteKeyword(state) {
      if (state.focusedKeyword !== null && state.vocabList[state.currentListIndex]) {
        state.vocabList[state.currentListIndex] = state.vocabList[state.currentListIndex].filter(
          (_, index) => index !== state.focusedKeyword
        );
        state.focusedKeyword = null;
      }
    },

    deleteKeywordFromTempVocabulary(state) {
      if (state.focusedKeyword !== null && state.tempVocabulary[state.currentListIndex]) {
        state.tempVocabulary[state.currentListIndex] = state.tempVocabulary[state.currentListIndex].filter(
          (_, index) => index !== state.focusedKeyword
        );
        state.focusedKeyword = null;
      }
    },

    regenerateKeyword(state, action) {
      const { listIndex, keywordIndex, } = action.payload;
      const keyword = state.vocabList[listIndex]?.[keywordIndex];

      if (keyword) {
        if (!keyword.history) {
          keyword.history = [{ ...keyword }];
          keyword.currentIndex = 0;
          keyword.regenerateCount = 0;
        }

        const sourceListIndex = listIndex + 1 + keyword.regenerateCount;
        if (sourceListIndex < state.vocabList.length) {
          const newKeywordData = state.vocabList[sourceListIndex]?.[keywordIndex];

          if (newKeywordData) {
            keyword.history.push(newKeywordData);
            keyword.currentIndex = keyword.history.length - 1;
            keyword.regenerateCount += 1;

            state.vocabList[listIndex][keywordIndex] = {
              ...newKeywordData,
              history: keyword.history,
              currentIndex: keyword.currentIndex,
              regenerateCount: keyword.regenerateCount
            };
          }
        }
      }
    },
    regenerateKeywordInTempVocabulary(state, action) {
      const { listIndex, keywordIndex } = action.payload;


      if (state.tempVocabulary.keywords_list[listIndex]) {

        if (state.tempVocabulary.keywords_list[listIndex][keywordIndex]) {
          const keyword = state.tempVocabulary.keywords_list[listIndex][keywordIndex];

          if (!keyword.history) {
            keyword.history = [{ ...keyword }];
            keyword.currentIndex = 0;
            keyword.regenerateCount = 0;
          }

          const sourceListIndex = listIndex + 1 + keyword.regenerateCount;
          if (sourceListIndex < state.tempVocabulary.keywords_list.length) {
            const nextKeyword = state.tempVocabulary.keywords_list[sourceListIndex]?.[keywordIndex];

            if (nextKeyword) {
              keyword.history.push(nextKeyword);
              keyword.currentIndex = keyword.history.length - 1;
              keyword.regenerateCount += 1;

              state.tempVocabulary.keywords_list[listIndex][keywordIndex] = {
                ...nextKeyword,
                history: keyword.history,
                currentIndex: keyword.currentIndex,
                regenerateCount: keyword.regenerateCount
              };


            }

          }
        }
      }
    }

    ,

    // Navigate keyword history in vocabList
    navigateKeywordHistory(state, action) {
      const { listIndex, keywordIndex, direction } = action.payload;
      const keyword = state.vocabList[listIndex]?.[keywordIndex];

      if (keyword && keyword.history) {
        const newIndex = keyword.currentIndex + (direction === 'next' ? 1 : -1);

        if (newIndex >= 0 && newIndex < keyword.history.length) {
          keyword.currentIndex = newIndex;
          state.vocabList[listIndex][keywordIndex] = {
            ...keyword.history[newIndex],
            history: keyword.history,
            currentIndex: newIndex,
            regenerateCount: keyword.regenerateCount
          };
        }
      }
    },




    navigateKeywordHistoryInTempVocabulary(state, action) {
      const { listIndex, keywordIndex, direction } = action.payload;
      const keyword = state.tempVocabulary.keywords_list[listIndex]?.[keywordIndex];

      if (keyword && keyword.history) {
        const newIndex = keyword.currentIndex + (direction === 'next' ? 1 : -1);

        if (newIndex >= 0 && newIndex < keyword.history.length) {
          state.tempVocabulary.keywords_list[listIndex][keywordIndex] = {
            ...keyword.history[newIndex],
            history: keyword.history,
            currentIndex: newIndex,
            regenerateCount: keyword.regenerateCount,
          };
        } else {
          console.warn('New index out of bounds');
        }
      } else {
        console.warn('Keyword or history not found');
      }
    }
    ,


    setTranscriptText(state, action) {
      state.transcriptText = action.payload;
    },

    addTempTranscript(state, action) {
      state.tempTranscript = action.payload;
    },

    addTranscriptToHistory(state, action) {
      const { transcript, identifier } = action.payload;

      state.currentIdentifier = identifier || state.currentIdentifier;

      state.transcriptHistory.push({
        transcript,
        identifier: state.currentIdentifier
      });

      state.currentTranscriptIndex = state.transcriptHistory.length - 1;
    },


    navigateTranscriptHistory(state, action) {
      const direction = action.payload.direction;

      if (direction === "prev" && state.currentTranscriptIndex > 0) {
        state.currentTranscriptIndex -= 1;
      } else if (direction === "next" && state.currentTranscriptIndex < state.transcriptHistory.length - 1) {
        state.currentTranscriptIndex += 1;
      }

      state.transcriptText = state.transcriptHistory[state.currentTranscriptIndex]?.transcript;
      state.currentIdentifier = state.transcriptHistory[state.currentTranscriptIndex]?.identifier;
    },


    editCurrentTranscript(state, action) {
      const { newTranscript } = action.payload;
      const index = state.currentTranscriptIndex;

      if (index >= 0 && index < state.transcriptHistory.length) {
        state.transcriptHistory[index].transcript = newTranscript;
        state.transcriptText = newTranscript;
      }
    },

    updateIdentifier(state, action) {
      state.transcriptHistory = [];
      state.currentTranscriptIndex = 0;
      state.transcriptText = "";
      state.currentIdentifier = action.payload;
    },

    clearTranscriptHistory(state) {
      state.transcriptHistory = [];
      state.currentTranscriptIndex = 0;
      state.transcriptText = "";
    },
    clearTempTranscript(state) {
      state.tempTranscript = [];
    },
  },
});

export const {
  setVocabList,
  clearVocabState,
  setTempVocabulary,
  clearTempVocabulary,
  setCurrentListIndex,
  setFocusedKeyword,
  setShowModal,
  deleteKeyword,
  deleteKeywordFromTempVocabulary,
  regenerateKeyword,
  regenerateKeywordInTempVocabulary,
  navigateKeywordHistory,
  navigateKeywordHistoryInTempVocabulary,
  setTranscriptText,
  addTranscriptToHistory,
  addTempTranscript,
  navigateTranscriptHistory,
  editCurrentTranscript,
  updateIdentifier,
  clearTranscriptHistory,
  clearTempTranscript,
} = vocabSlice.actions;

export default vocabSlice.reducer;
