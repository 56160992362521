import React from "react";
import "./AudioList.css";

const DeleteListModal = ({
  show,
  handleClose,
  recordings,
  handleDelete,
  handleDeleteAll,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="delete-list-overlay">
      <div className="delete-list-modal">
        <div className="delete-list-header">
          <h2>Delete Recording(s)</h2>
          <span className="delete-list-close" onClick={handleClose}>
            <i class="bi bi-x-circle"></i>
          </span>
        </div>

        <div className="delete-list-body">
          {recordings.length === 0 ? (
            <p>No recordings available to delete.</p>
          ) : (
            recordings.map((recording) => (
              <div key={recording.id} className="delete-list-item">
                <span>{recording.recording_name}</span>
                <button
                  className="delete-list-btn"
                  data-id={recording.id}
                  onClick={() => handleDelete(recording.id)}
                >
                  Delete
                </button>
              </div>
            ))
          )}
        </div>

        <div className="delete-list-footer">
          <button className="delete-all-btn" onClick={handleDeleteAll}>
            Delete All
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteListModal;
