// Modal.js
import React from "react";
import "./ReasearchModal.css";

const ResearchModal = ({ show, onClose, message }) => {
  if (!show) return null; // If show is false, don't render the modal

  return (
    <div className="research-modal-overlay">
      <div className="research-modal-content">
        <p>{message}</p>
        <button className="research-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ResearchModal;
