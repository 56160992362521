// import React, { useState } from "react";
// import "./Research.css";

// const ResearchSelected = ({ keywords, setUserResponses, onClickSubmit }) => {
//   const [data, setData] = useState(keywords);

//   // State to track the selection status for each keyword
//   const [selectionStatus, setSelectionStatus] = useState(
//     data.reduce((acc, item) => ({ ...acc, [item.source_language]: null }), {})
//   );

//   const handleIconClick = (index, answerType) => {
//     const keyword = data[index].source_language;

//     // Determine new selection based on current status
//     const currentSelection = selectionStatus[keyword];
//     const newSelection = currentSelection === answerType ? null : answerType;

//     // Update userResponses and selectionStatus with the new selection
//     setUserResponses((prevResponses) => ({
//       ...prevResponses,
//       [keyword]: newSelection,
//     }));

//     setSelectionStatus((prevStatus) => ({
//       ...prevStatus,
//       [keyword]: newSelection,
//     }));
//   };

//   const handleSubmit = () => {
//     // Handle submission logic (send userResponses to the next page or API)
//     onClickSubmit(); // This will handle the submit logic like navigation or saving
//   };

//   return (
//     <div className="research_container">
//       <h2>Scoring Units to Research</h2>
//       <p>
//         Look up the vocabulary units below in a reputable dictionary or
//         glossary.
//       </p>

//       <table className="scoring-table">
//         <thead>
//           <tr>
//             <th>Source Language</th>
//             <th>Target Language ({data.length} Scoring Units)</th>
//             <th>Answers</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <tr key={index}>
//               <td>{item.source_language}</td>
//               <td>{item.target_language}</td>
//               <td className="display">
//                 <span
//                   className={`icon correct ${
//                     selectionStatus[item.source_language] === "incorrect"
//                       ? "disabled"
//                       : ""
//                   } ${
//                     selectionStatus[item.source_language] === "correct"
//                       ? "selected"
//                       : ""
//                   }`}
//                   onClick={() => handleIconClick(index, "correct")}
//                 >
//                   &#10004;
//                 </span>
//                 <span
//                   className={`icon incorrect ${
//                     selectionStatus[item.source_language] === "correct"
//                       ? "disabled"
//                       : ""
//                   } ${
//                     selectionStatus[item.source_language] === "incorrect"
//                       ? "selected"
//                       : ""
//                   }`}
//                   onClick={() => handleIconClick(index, "incorrect")}
//                 >
//                   &#10008;
//                 </span>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <button className="submit-btn" onClick={handleSubmit}>
//         Submit
//       </button>
//     </div>
//   );
// };

// export default ResearchSelected;
import React, { useState } from "react";
import "./ResearchSelected.css";

const ResearchSelected = ({ keywords, setUserResponses, onClickSubmit }) => {
  const [data] = useState(keywords);

  // State to track the selection status for each keyword
  const [selectionStatus, setSelectionStatus] = useState(
    data.reduce((acc, item) => ({ ...acc, [item.source_language]: null }), {})
  );

  const handleIconClick = (index, answerType) => {
    const keyword = data[index].source_language;
    const newSelection =
      selectionStatus[keyword] === answerType ? null : answerType;

    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [keyword]:
        newSelection === "correct"
          ? "right"
          : newSelection === "incorrect"
          ? "wrong"
          : null,
    }));

    setSelectionStatus((prevStatus) => ({
      ...prevStatus,
      [keyword]: newSelection,
    }));
  };

  const handleSubmit = () => {
    onClickSubmit();
  };

  return (
    <div className="research_container">
      <h2>Scoring Units to Research</h2>
      <p>
        Look up the vocabulary units below in a reputable dictionary or
        glossary.
      </p>

      <div className="scoring-table-content">
        <table className="scoring-table">
          <thead>
            <tr>
              <th>Source Language</th>
              <th>Target Language ({data.length} Scoring Units)</th>
              <th>Answers</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.source_language}</td>
                <td>{item.target_language}</td>
                <td className="display">
                  <span
                    className={`icon correct ${
                      selectionStatus[item.source_language] === "correct"
                        ? "selected disabled"
                        : ""
                    }`}
                    onClick={() =>
                      selectionStatus[item.source_language] !== "correct" &&
                      handleIconClick(index, "correct")
                    }
                  >
                    &#10004;
                  </span>
                  <span
                    className={`icon incorrect ${
                      selectionStatus[item.source_language] === "incorrect"
                        ? "selected disabled"
                        : ""
                    }`}
                    onClick={() =>
                      selectionStatus[item.source_language] !== "incorrect" &&
                      handleIconClick(index, "incorrect")
                    }
                  >
                    &#10008;
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-center pt-3">
        <button className="submit-btn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResearchSelected;
