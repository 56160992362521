import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import { UserContext } from "../../../providers/UserContext";
import { generateTopicDetails } from "../../../services/vocab.service";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";

const VocablaryTopic = () => {
  const { currentUser } = useContext(UserContext);
  const { formData, updateFormData } = useContext(FormContext);
  const [topic, setTopic] = useState(formData.topic || "");
  const [topicAdditionInformation, setTopicAdditionInformation] = useState(
    formData.topicAdditionInformation || []
  );
  const [additionalInfoValues, setAdditionalInfoValues] = useState({});
  const [error, setError] = useState("");
  const [isNext, setIsNext] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErorModal, setShowErosModal] = useState(false);
  const navigate = useNavigate();

  const getInfo = async (e) => {
    if (!topic) {
      e.preventDefault();
      setError("Please enter a topic.");
      toast.error("Please enter a topic.");
      return;
    }

    // Validate word count
    const wordCount = topic
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;
    if (wordCount < 5) {
      e.preventDefault();
      setError("The topic must contain at least 5 words.");
      toast.error("The topic must contain at least 5 words.");
      return;
    }

    try {
      setShowErosModal(false); // Initially hide error modal
      setShowModal(true); // Show loading modal
      const result = await generateTopicDetails(currentUser.access, topic);
      console.log("result", result);

      if (result && result.questions) {
        setTopicAdditionInformation(result.questions);
        setShowModal(false); // Hide the loading modal
        // setShowErosModal(true); // Show the error modal
        setAdditionalInfoValues(
          result.questions.reduce((acc, item) => {
            acc[item.label.toLowerCase().replace(/ /g, "_")] = ""; // Initialize each field
            return acc;
          }, {})
        );
        setIsNext(true);
      }
    } catch (error) {
      console.error("Error during the API call:", error);
      setShowModal(false); // Hide the loading modal
      setShowErosModal(true); // Show the error modal
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setAdditionalInfoValues((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTopicAdditionInformation((prev) =>
      prev.map(
        (item) =>
          item.label.toLowerCase().replace(/ /g, "_") === name
            ? { ...item, value: value } // Update the matching item's value
            : item // Keep other items unchanged
      )
    );
  };

  const handleTopicChange = (e) => {
    const input = e.target.value;
    const wordCount = input
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;

    setTopic(input);

    // Update form data and clear the error if word count is sufficient
    if (wordCount >= 5) {
      updateFormData("topic", input);
      setError("");
    }
  };

  const handleNextClick = (e) => {
    if (!topic) {
      e.preventDefault();
      setError("Please enter a topic.");
      toast.error("Please enter a topic.");
      return;
    }

    // Validate word count
    const wordCount = topic
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;
    if (wordCount < 5) {
      e.preventDefault();
      setError("The topic must contain at least 5 words.");
      toast.error("The topic must contain at least 5 words.");
      return;
    }

    // Save additional information values
    updateFormData("topicAdditionInformation", topicAdditionInformation);
    console.log("topicAdditionInformation", topicAdditionInformation);
    navigate("/vocabulary-language-level");
  };

  const goBack = () => {
    if (isNext) {
      setIsNext(!isNext);
    } else {
      navigate("/vocabulary-language-level");
    }
  };

  const closeModal = () => {
    // handleNextClick();
    setShowErosModal(false);
  };

  return (
    <div className="min-screen screen5">
      {showModal && (
        <ModalComponent modalText="Processing your topic. Please wait." />
      )}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            {isNext
              ? `Maximize the power of your flashcards by sharing as many additional details as possible below.`
              : `Providing detailed information on your topic will result in more detailed flashcards. For example, if your topic is a doctor's visit, include details such as the reason for the visit and relevant information about the patient. The more specific you are, the better the results!`}
          </p>
          {isNext ? (
            <form className="form_">
              {topicAdditionInformation.map((item, index) => (
                <div key={index} className="form-group">
                  <label className="form-label">{item.label}</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    name={item.label.toLowerCase().replace(/ /g, "_")} // Generate a unique name
                    placeholder={`Enter ${item.placeholder.toLowerCase()}`}
                    value={item.value}
                    onChange={handleInputChange}
                  /> */}

              <textarea
                rows="2"
                cols="50"
                className="form-control mb-2"
                onChange={handleInputChange}
                name={item.label.toLowerCase().replace(/ /g, "_")} // Generate a unique name
                    placeholder={`${item.placeholder}`}
                    value={item.value}
              ></textarea>
                </div>
              ))}
            </form>
          ) : (
            <form className="form_">
              <label className="form-label">
                What topic would you like your flashcards to focus on?{" "}
                {/* <span className="text-danger">*</span> */}
              </label>
              <textarea
                rows="3"
                cols="50"
                className="form-control"
                value={topic}
                onChange={handleTopicChange}
                placeholder="Please provide at least 5 words to describe your topic."
              ></textarea>
              {/* <p className="hint-text">
                Please provide at least three words that best describe your
                topic.
              </p> */}
              {error && (
                <p
                  className="topic-error-message-dark"
                  style={{ marginTop: "15px" }}
                >
                  {error}
                </p>
              )}
            </form>
          )}
          <div className="btn-list">
            <Button className="small" onClick={goBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="currentColor"
              >
                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
              </svg>
              Back
            </Button>

            {isNext ? (
              <Button className="small" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            ) : (
              <Button className="small" onClick={getInfo}>
                submit
              </Button>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={getInfo}
        />
      )}
    </div>
  );
};

export default VocablaryTopic;
