import axios from "axios";


import { REACT_APP_ADD_SHARED_PRACTICE_LIST, REACT_APP_COPY_VOCAB_API, REACT_APP_DASHBOARD, REACT_APP_DASHBOARD_SHARED_PRACTICE, REACT_APP_GET_VOCAB_API, REACT_APP_SAVE_VOCAB_API, REACT_APP_SINGLE_REGENERATE, REACT_APP_TOPIC_INFO_API, REACT_APP_UPDATE_VOCAB_API, REACT_APP_VOCAB_API } from "../constants";
import { toast } from "react-toastify";

export const generateVocabulary = async (token, formData, region, feedbacks, maxCount) => {
  if (!token) {
    throw new Error("No access token found.");
  }
  try {
    const response = await fetch(REACT_APP_VOCAB_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        num_keywords: parseInt(formData.vocabularyCount, 10),
        source_language: formData.sourceLanguage,
        target_language: formData.targetLanguage,
        topic: formData.topic,
        topic_additional_information: formData.topicAdditionInformation,
        level: formData.level,
        region: region,
        num_lists: maxCount,
        feedbacks: feedbacks
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during the API call:", error);
    throw error;
  }
};

export const singleRegenerateVocabulary = async (token, formData, region, exclude_keywords, feedbacks, maxCount) => {
  if (!token) {
    throw new Error("No access token found.");
  }
  try {
    const response = await fetch(REACT_APP_SINGLE_REGENERATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        num_keywords: 1,
        source_language: formData.sourceLanguage,
        target_language: formData.targetLanguage,
        exclude_keywords: exclude_keywords,
        topic: formData.topic,
        topic_additional_information: formData.topicAdditionInformation,
        level: formData.level,
        region: region,
        num_lists: maxCount,
        feedbacks: feedbacks
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during the API call:", error);
    throw error;
  }
};
export const generateTopicDetails = async (token, topic) => {
  if (!token) {
    throw new Error("No access token found.");
  }
  try {
    const response = await fetch(REACT_APP_TOPIC_INFO_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        number_of_questions: 3,
        topic: topic,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
      // return false;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during the API call:", error);
    throw error;
    // return false;
  }
};

/// Save Vocab //

export const saveVocabPractice = async (dataToSubmit, accessToken) => {
  try {
    const response = await fetch(REACT_APP_SAVE_VOCAB_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToSubmit),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

//// Update Vocab //

export const updateVocabulary = async (identifier, dataToSubmit, accessToken) => {
  try {
    const response = await fetch(`${REACT_APP_UPDATE_VOCAB_API}${identifier}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataToSubmit),
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error.includes("already in use")) {
        throw new Error("The practice name you entered is already in use.");
      } else {
        throw new Error(errorData.error);
      }
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};


//////generate vocab base on detail on edit page  update////
export const editgenerateVocabulary = async (vocabularyDetails) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(REACT_APP_VOCAB_API, vocabularyDetails, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error generating vocabulary:', error);
    throw error;
  }
};


/// fetch detail of vocab // by identifier 

export const fetchSourceLanguage = async (accessToken, identifier) => {
  try {
    const response = await fetch(REACT_APP_GET_VOCAB_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ identifier }),
    });

    if (!response.ok) {

      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch source language.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching source language:", error.message);
    throw error;
  }
};


// Define the fetchTopics service
export const fetchTopicsService = async (email, token) => {
  try {
    if (!email) {
      throw new Error("Email not found.");
    }

    const response = await axios.post(
      REACT_APP_DASHBOARD, // Use the environment variable properly
      { email: email }, // This is the request payload
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response.status", response.status)
    if (response.status === 404) {
      return { success: false, noData: true };
    }

    // Axios handles responses with 'ok' or 'status', so no need for 'response.ok'
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // The data is already parsed as JSON by axios
    const data = response.data;

    // Check if the response data contains 'practice_info'
    if (data && Array.isArray(data.practice_info)) {
      return { success: true, data: data.practice_info };
    } else {
      throw new Error("Unexpected data format: 'practice_info' is missing or not an array.");
    }
  } catch (error) {
    if (error.status === 404) {
      return { success: false, noData: true };
    }
    console.error("Fetch error:", error);
    return { success: false, error: error.message };
  }
};

// Define the shared Topics service
export const fetchSharedTopicsService = async (email, token) => {
  try {
    if (!email) {
      throw new Error("Email not found.");
    }

    const response = await axios.post(
      REACT_APP_DASHBOARD_SHARED_PRACTICE, // Use the environment variable properly
      { email: email }, // This is the request payload
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response.status", response.status)
    if (response.status === 404) {
      return { success: false, noData: true };
    }

    // Axios handles responses with 'ok' or 'status', so no need for 'response.ok'
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // The data is already parsed as JSON by axios
    const data = response.data;

    // Check if the response data contains 'practice_info'
    if (data && Array.isArray(data.shared_practices)) {
      return { success: true, data: data.shared_practices };
    } else {
      throw new Error("Unexpected data format: 'practice_info' is missing or not an array.");
    }
  } catch (error) {
    if (error.status === 404) {
      return { success: false, noData: true };
    }
    console.error("Fetch error:", error);
    return { success: false, error: error.message };
  }
};

// Service for copying vocabulary
export const copyVocabService = async (itemToCopy, token) => {
  try {
    if (!token) {
      throw new Error("Token not found.");
    }

    const response = await axios.post(
      REACT_APP_COPY_VOCAB_API,
      { identifier: itemToCopy },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      toast.success(`Practice Copied Successfully`);
      return { success: true };
    }
  } catch (error) {
    console.error("Fetch error:", error);
    toast.error(error.message);
    return { success: false, error: error.message };
  }
};


// add In Shared Practices Service

export const addInSharedPracticesService = async (payload, token) => {
  try {
    if (!token) {
      throw new Error("Token not found.");
    }

    const response = await axios.post(
      REACT_APP_ADD_SHARED_PRACTICE_LIST,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      // toast.success(`Practice copied successfully`);
      return { success: true };
    }
  } catch (error) {
    console.error("Fetch error:", error);
    // toast.error(error.message);
    return { success: false, error: error.message };
  }
};
