import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const TranscriptGeneration = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [transcriptLength, setTranscriptLength] = useState(
    formData.transcript_length || ""
  );
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;

    setTranscriptLength(value);
    updateFormData("transcript_length", value);
  };
  const validateInput = () => {
    const length = parseInt(transcriptLength, 10);

    if (transcriptLength.trim() === "") {
      setError("Transcript length cannot be empty.");
      toast.error("Transcript length cannot be empty.");
      return false;
    }

    if (isNaN(length)) {
      setError("Please enter a valid number.");
      toast.error("Please enter a valid number.");
      return false;
    }

    if (length <= 0) {
      setError("Please enter a positive number.");
      toast.error("Please enter a positive number.");
      return false;
    }

    if (length < 50) {
      setError("Please enter a minimum 50 words desired length.");
      toast.error("Please enter a minimum 50 words desired length.");
      return false;
    }

    if (length > 1500) {
      setError("The maximum allowed length is 1500.");
      toast.error("The maximum allowed length is 1500.");
      return false;
    }

    setError("");
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validateInput()) {
      navigate(`/transcript-type/${id}`);
    }
  };

  const backClick = (e) => {
    e.preventDefault();
    navigate(`/congratulation/${id}`);
  };
  return (
    <div className="min-screen screen12">
      <HeaderComponent />
      <div className="mainContentBox transcript-length-div">
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          {/* <p className="des">
            Please enter the word count to help us generate accurate and
            comprehensive transcripts. Providing the word length will assist us
            in delivering better results for your transcription needs.
          </p> */}
          <form className="form_" onSubmit={handleNextClick}>
            <label className="form-label">
              How many words should your transcript be?
              {/* <span className="text-danger">*</span> */}
            </label>
            <input
              type="number"
              className="form-control"
              value={transcriptLength}
              onChange={handleInputChange}
              placeholder="(Ex: 500)"
              required
            />
          </form>
          {error && (
            <div className="trans-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link className="noDecoration">
              <button className="small" onClick={backClick}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </button>
            </Link>
            <Link className="noDecoration">
              <button className="small" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TranscriptGeneration;
