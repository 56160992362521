import React from 'react';
import PropTypes from 'prop-types';
import './SignupButton.css';    

const Button = ({ type, className, onClick, children }) => {
    return (
        <button type={type} className={`btn ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Button.defaultProps = {
    onClick: () => { },
};

export default Button;
