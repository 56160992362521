// import React from "react";
// import "./OpsModal.css";
// import images from "../../../images";
// const OpsModal = ({ show, onNextStep, onClose, model }) => {
//   if (!show) {
//     return null;
//   }

//   return (
//     <div className="Opsmodal" onClick={onClose}>
//       <div className="Opsmodal-content" onClick={(e) => e.stopPropagation()}>
//         <div className="Opsmodal-icon">
//           <img src={images.sadface} alt="Sad face icon" />

//           <span className="close-button" onClick={onClose}>
//             &times;
//           </span>
//         </div>
//         <h2 style={{ color: "#17353B", fontWeight: "700" }}>OOPS!</h2>
//         <p style={{ color: "black" }}>
//           You have reached the maximum number of 2 regenerations for this
//           {model} section. However, you may still edit any of the language
//           equivalents manually.
//         </p>
//         <div>
//           <button id="regenerate-button" onClick={onClose}>
//             Continue
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OpsModal;
import React from "react";
import "./OpsModal.css";
import images from "../../../images";

const OpsModal = ({ show, onClose, context }) => {
  if (!show) {
    return null;
  }

  // Define the message based on the context
  const getMessage = (context) => {
    switch (context) {
      case "vocabulary":
        return "You have reached the maximum number of 2 regenerations for this vocabulary section. However, you may still edit any of the language equivalents manually.";
      case "transcript":
        return "You have reached the maximum number of 2 regenerations for this transcript section. However, you may still edit the transcript manually.";
      case "audio":
        return "You have reached the maximum number of 2 audios. Please select from the current audios to continue with your practice generation.";
      default:
        return "You have reached the maximum limit.";
    }
  };

  return (
    <div className="Opsmodal" onClick={onClose}>
      <div className="Opsmodal-content" onClick={(e) => e.stopPropagation()}>
        <div className="Opsmodal-icon">
          <img src={images.sadface} alt="Sad face icon" />
          <span className="close-button" onClick={onClose}>
            <i class="bi bi-x-circle"></i>
          </span>
        </div>
        <h2 style={{ color: "#17353B", fontWeight: "700" }}>OOPS!</h2>
        <p style={{ color: "black" }}>{getMessage(context)}</p>
        <div>
          <button id="regenerate-button" onClick={onClose}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpsModal;
