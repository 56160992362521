import React, { useEffect, useState, useRef, useContext } from "react";
import "./AudioGenerated.css";
import HeaderComponent from "components/Header/Header";
import { useNavigate, useParams } from "react-router";
import { REACT_APP_GET_TRANSCRIPT_API, API_URL } from "../../../constants";
import Footer from "../../../components/Footer/Footer";
import TranscriptSection from "../../../components/TranscriptComponent/TranscriptComponent";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import axios from "axios";
import { UserContext } from "../../../providers/UserContext";
import images from "../../../images";
const AudioGenerated = () => {
  const [transcriptText, setTranscriptText] = useState("");
  const { currentUser } = useContext(UserContext);
  const [audios, setAudios] = useState([]);
  const audioRefs = useRef([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const fetchTranscriptText = async () => {
    // const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        REACT_APP_GET_TRANSCRIPT_API,
        {
          identifier: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.access}`,
          },
        }
      );
      if (response.data && response.data.transcripts[0]) {
        setTranscriptText(response.data.transcripts[0].transcript);

        const audios = response.data.save_audios;
        console.log(audios, "audios");
        const audio = audios.filter((item) => item.audio_file_path != null);
        setAudios(audio);
      }
    } catch (error) {
      console.error("Error fetching the transcript:", error);
    }
  };

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) => paragraph.replace(/<break \/>/g, "\n \n"))
    .join("\n\n");
  const BacktoDashboard = () => {
    navigate(-1);
    // navigate("/practice-dashboard");
  };
  const handlePlayStatus = (status) => {
    console.log("Playback status:", status); // You can check this in the console
  };
  return (
    <div className=" transcript-audio-container">
      <HeaderComponent />

      <main className="content-audio">
        <div>
          <button
            className="flash-card-back-button-audio"
            onClick={BacktoDashboard}
          >
            <img
              className="back-dash-right"
              src={images.ArrowLeftwhite}
              alt=""
            />
          </button>
        </div>
        <h2 className="audio-title">Generated Transcript</h2>
        <TranscriptSection transcriptText={formattedTranscript} />
        {/* <p>Congratulations! Your audio has been successfully created. Let's move forward to complete the practice generation.</p> */}
        <section className="audio-section" style={{ marginBottom: "15px" }}>
          <h2 className="audio-title" style={{ marginBottom: "0" }}>
            Generated Audio
          </h2>
          {audios && audios.length > 0 ? (
            audios.map((audioData, index) => (
              <div key={index}>
                <AudioPlayer
                  index={index}
                  toggles={[true]}
                  audioData={audioData}
                  API_URL={API_URL}
                  src={`${API_URL}/media/${audioData.audio_file_path}`}
                  audioRefs={audioRefs}
                  playStatus={handlePlayStatus}
                />
              </div>
            ))
          ) : (
            <p>Audio not found</p>
          )}
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default AudioGenerated;
