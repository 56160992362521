import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./AudioRecording.css";
import images from "../../images";

import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";
import AudioSaveModal from "../../components/Modal/AudioSave/AudioSave";
import { useAudioRecorder } from "react-audio-voice-recorder";
import EvaluateModal from "../../components/Modal/EvaluateModel/EvaluateModal";
import AudioCancelModal from "../../components/Modal/AudioCancle/AudioCancle";
import { fetchTranscriptService } from "../../services/transcript.service";
import { submitRecordingService } from "../../services/audio.service";

function AudioRecordingPlayer() {
  const { id } = useParams();
  const [transcriptText, setTranscriptText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEvaluateModalOpen, setIsEvaluateModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [audioFile, setAudioFile] = useState();
  const navigate = useNavigate();
  const [isPaused, setIsPaused] = useState(false);

  const handleTogglePauseResume = () => {
    togglePauseResume();
    setIsPaused(!isPaused);
  };
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
  } = useAudioRecorder();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    const fetchTranscriptText = async () => {
      try {
        const response = await fetchTranscriptService(id, token);
        if (response) {
          setTranscriptText(response.transcript.transcript);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id) {
      fetchTranscriptText();
    }
  }, [id]);
  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) =>
      paragraph.replace(/<break \/>/g, "\n \n").replace(/<break\/>/g, "\n \n")
    )
    .join("\n\n");

  useEffect(() => {
    if (!recordingBlob) return;

    const audioFile = blobToFile(recordingBlob, `${id}_audioRecording.wav`);
    console.log("Audio File:", audioFile);
    setAudioFile(audioFile);
    setIsModalOpen(true);
  }, [recordingBlob, id]);

  const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: blob.type });
  };

  const submitAudio = async (data) => {
    const email = localStorage.getItem("email");
    const accessToken = localStorage.getItem("accessToken");

    const response = await submitRecordingService(
      id,
      audioFile,
      data.audioName,
      data.removeSilence,
      email,
      accessToken
    );

    if (response.success) {
      setIsModalOpen(false);
      // Optionally, handle further logic, e.g., opening another modal
      setIsEvaluateModalOpen(true);
    }
  };

  const goToRecordingWithKeyword = () => {
    navigate(`/recording-with-keyword/${id}`);
  };

  const evaluateModalClose = () => {
    setIsEvaluateModalOpen(false);
  };
  const handleCancelModalOpen = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  const handleCancelConfirm = () => {
    setIsCancelModalOpen(false);
  };
  const backClick = () => {
    // e.preventDefault();
    navigate(-1);
  };
  return (
    <div className=" player-container">
      <HeaderWhiteComponent className="headerr" />

      <div className="mainContentBox">
        <div className="topBarcontentRight-player">
          <div className="userName">
            <button className="btn btn-primary" onClick={backClick}>
              <img
                className="back-dash-right"
                src={images.ArrowLeftwhite}
                alt=""
              />
              Back
            </button>
          </div>
        </div>
        <div className="player-content">
          <div className="transcript-record-container">
            <p>{formattedTranscript}</p>
          </div>
        </div>
        <div className="footer-audio-recording">
          <div className="audio-recording-player">
            {!isRecording ? (
              <div className="record-container" onClick={startRecording}>
                <img
                  src={images.mike}
                  alt="Microphone"
                  className="microphone-icon"
                />
                <p className="record-text">Tap to Record</p>
              </div>
            ) : (
              <div className="recording-controls">
                <div className="">
                  {/* <p className="recordingTime">Recording...</p> */}
                  <p className="recordingTime">
                    {isRecording
                      ? isPaused
                        ? "Recording paused..."
                        : "Recording..."
                      : ""}
                  </p>
                </div>

                <div className="audioControlbtn">
                  <button className="control-btn" onClick={stopRecording}>
                    <img src={images.stop} alt="Stop and Save" />
                    <p>Stop & Save</p>
                  </button>

                  {/* <button className="control-btn" onClick={togglePauseResume}>
                    <img src={images.pause} alt="Pause" />
                    <p>Pause</p>
                  </button> */}
                  <button
                    className="control-btn"
                    onClick={handleTogglePauseResume}
                  >
                    <img
                      src={images.pause}
                      alt={isPaused ? "Resume" : "Pause"}
                    />
                    <p>{isPaused ? "Resume" : "Pause"}</p>
                  </button>
                  <button
                    className="control-btn"
                    onClick={handleCancelModalOpen}
                  >
                    <img src={images.cancle} alt="Cancel" />
                    <p>Cancel</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AudioSaveModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={(data) => submitAudio(data)}
      />

      <EvaluateModal
        isOpen={isEvaluateModalOpen}
        onClose={evaluateModalClose}
        onNowClick={goToRecordingWithKeyword}
      />
      <AudioCancelModal
        show={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleConfirm={handleCancelConfirm}
      />
    </div>
  );
}

export default AudioRecordingPlayer;
