import React, { useState } from "react";
import "./Subscription.css";

const SubscriptionModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              <i class="bi bi-x-circle"></i>
            </button>
            <h2 className="modal-headline">
              Unlock Your First Practice Session with Interpreter Pro AI!
            </h2>
            <p className="modal-body">
              To begin, a subscription is required. Here's how you can get
              started:
            </p>
            <div className="modal-actions">
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
                className="modal-button canclebtn"
              >
                View Pricing & Register
              </a>
              {/* <a
                href="https://interpreterpro.ai/"
                target="_blank"
                rel="noopener noreferrer"
                className="modal-button canclebtn"
              >
                Learn More About Our Platform
              </a> */}

              <p style={{ color: "black" }}>
                Want to learn more about our platform? Feel free to
                <a
                  href="https://interpreterpro.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="explore"
                >
                  explore
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionModal;
