import React from "react";
import "./ConfirmationModal.css"; // Assuming you want to style it separately

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    // <div className="confirmation-modal">
    //   <p>Are you sure you want to continue with this practice?</p>
    //   <button className="play-sample-button " onClick={onConfirm}>Yes</button>
    //   <button className="play-sample-button select-button mt-2" onClick={onCancel}>No</button>
    // </div>


  <>
    
    <div
      className={`modal fade show`}
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="delete-item">
              <h2>Are you sure you want to continue with this audio?</h2>
            </div>
            <div className="modal-btn-group">
              <button type="button" className="edit-modal-btn edit-modal-btn-no" onClick={onCancel}>
                No
              </button>
              <button
                type="button"
                className="edit-modal-btn edit-modal-btn-yes"
                style={{ margin: "0px" }}
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>



  );
};

export default ConfirmationModal;
