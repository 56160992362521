import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import images from "../../images";
import "./Header.css";
import { useLocation, useNavigate } from "react-router";
import { logout } from "../../services/user.service";
import { UserContext } from "../../providers/UserContext";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import SubscriptionModal from "../Modal/Subscription/Subscription";

function HeaderComponent() {
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { currentUser, resetCurrentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [userrefreshToken] = useState(currentUser.access || "");
  // const [userName] = useState(currentUser.first_name || "");
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!accessToken) {
      localStorage.clear();
      navigate("/login");
      return;
    }
  }, [accessToken, currentUser]);

  // const logoClick = () => {
  //   navigate("/welcome");
  // };

  const DashboardClick = (e) => {
    e.stopPropagation();
    if (location.pathname === "/practice-dashboard") {
      // Reload the page without query parameters by setting the window location explicitly
      window.location.href = "/practice-dashboard";
    } else {
      // Navigate to the dashboard
      navigate("/practice-dashboard");
    }
  };
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // if (currentUser?.last_name) {
    //   setShowModal(false);
    // } else {
    // setShowModal(true);
    // }

    const storedFirstName = currentUser.first_name;
    if (storedFirstName) {
      const name =
        storedFirstName.charAt(0).toUpperCase() +
        storedFirstName.slice(1).toLowerCase();
      setFirstName(name);
    }
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentUser]);

  const handleLogoMouseEnter = () => setShowPopup(true);
  const handleLogoMouseLeave = () => setShowPopup(false);
  const handlePopupClose = () => setShowPopup(false);

  const isActiveDashboard = location.pathname === "/practice-dashboard";
  const logOutClick = async () => {
    try {
      if (!usertoken) {
        resetCurrentUser();
        localStorage.clear();
        navigate("/login");
        // throw new Error("No refresh token found.");
        return;
      }

      const data = await logout(usertoken, userrefreshToken);

      if (data.status) {
        throw new Error(`HTTP error! Status: ${data.message}`);
      }
      resetCurrentUser();
      // Clear authentication data from local storage
      localStorage.clear();
      // Redirect to login or home page
      navigate("/login");
    } catch (error) {
      resetCurrentUser();
      // Clear authentication data from local storage
      localStorage.clear();
      navigate("/login");
      console.error("Logout error:", error);
    }
  };
  const ProfileCliked = () => {
    navigate("/update-profile");
  };
  const generatePractice = () => {
    setShowPopup(false);
    navigate("/vocabulary-screen");
  };

  const commanCase = (e) => {
    e.stopPropagation();
    if (location.pathname === "/comman-use-case") {
      // Reload the page if already on dashboard
      window.location.reload();
    } else {
      // Navigate to the dashboard
      navigate("/comman-use-case");
    }
  };
  const SharedPractiseClik = () => {
    setShowPopup(false);
    navigate(`/practice-dashboard?sharedPractice=true`);
  };
  return (
    <Navbar
      bg="transparent"
      expand="lg"
      className={`custom-navbar ${isSticky ? "sticky" : ""}`}
    >
      <Navbar.Brand
        // onClick={logoClick}
        onMouseEnter={handleLogoMouseEnter}
        onMouseLeave={handleLogoMouseLeave}
      >
        {/* <img
          src={images.logoHigh}
          alt="logo"
          className="logo-img"
          onClick={logoClick}
        /> */}
        <HeaderLogo />
        {showPopup && (
          <div className="popup-menu">
            <div className="popmenu-first-div">
              <div className="logo-header">
                <div className="header-logo">
                  <img
                    src={images.bigLogo} // Replace with your logo path
                    alt="Logo"
                    className="header-logo-img"
                  />
                </div>
                <div className="headerlogo-text">
                  <span className="orange">INTERPRE</span>
                  <span className="headerlogo-text-black">TRAIN</span>
                </div>
              </div>
              <button className="popup-close" onClick={handlePopupClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </button>
            </div>
            <ul>
              <li onClick={DashboardClick}>Dashboard</li>
              <li onClick={generatePractice}>Create a Practice</li>
              {/* <li onClick={() => navigateTo("/add-credits")}>
                Add Practice Credits
              </li> */}
              <li onClick={commanCase}>See Common Use Cases</li>
              <li onClick={DashboardClick}>View Published Practices</li>
              <li onClick={SharedPractiseClik}>View Shared Practices</li>
              {/* <li onClick={() => navigateTo("/view-tutorials")}>
                View Tutorials
              </li> */}
              <li>
                {" "}
                <a
                  href="https://interpretrain.com/pages/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="popup-contactUS"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="ml-auto nav-links p-2">
          <Nav.Link
            className={`mb-1 ${isActiveDashboard ? "active" : ""}`}
            onClick={DashboardClick}
          >
            Dashboard
          </Nav.Link>
          {/* <Nav.Link className="mb-1" href="#">
            <img src={images.user} alt="user" className="user-icon" />
          </Nav.Link> */}
          <NavDropdown title={firstName} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={ProfileCliked}>
              Profile{" "}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logOutClick}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </Navbar>
  );
}

export default HeaderComponent;
