import React, { useState } from 'react';
import './Accordion.css';

const Accordion = ({ questions }) => {
  const [openSections, setOpenSections] = useState(Array(questions.length).fill(false)); // Track individual sections
  const [areAllSectionsOpen, setAreAllSectionsOpen] = useState(false); // Track global open/close state

  // Toggle a specific section
  const toggleSection = (index) => {
    const updatedSections = [...openSections];
    updatedSections[index] = !updatedSections[index];
    setOpenSections(updatedSections);
  };

  // Toggle all sections
  const toggleAllSections = () => {
    const newState = !areAllSectionsOpen;
    setOpenSections(Array(questions.length).fill(newState));
    setAreAllSectionsOpen(newState);
  };

  return (
    <div className="accordion">
      <button className="accordion-toggle-all" onClick={toggleAllSections}>
        {areAllSectionsOpen ? 'Collapse All' : 'Expand All'}
      </button>
      {questions.map((quiz, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-header" onClick={() => toggleSection(index)}>
            <h3>{quiz.question}</h3>
            <button className="accordion-toggle-single">
              {openSections[index] ? 'Collapse' : 'Expand'}
            </button>
          </div>
          <div className={`accordion-content ${openSections[index] ? 'open' : 'closed'}`}>
            {quiz.correct}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
