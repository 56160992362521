import React from "react";
import HeaderComponent from "../../components/Header/Header";

import SwiperComponent from "../../components/SwiperSlider/Swiper";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router";

function WelcomePage() {
  const navigate = useNavigate();

  const GetstartClicked = () => {
    navigate("/vocabulary-screen");
  };
  return (
    <div className="min-screen screen2 welcome-slider">
      <HeaderComponent />
      <div className="welcome-mainContentBox">
        <div className="slider-content">
          <h1 className="title">Welcome to Interpreter Pro AI!</h1>
          <p className="des">
            Click below to start creating your customized interpreting lab.
          </p>
          <button className="btn btn-light" onClick={GetstartClicked}>
            Get Started
          </button>
          <div className="slider-content">
            {/* <div className="navigation-box">
              <div className="swiper-button-next"> </div>
              <div className="swiper-button-prev"></div>
            </div> */}
            <SwiperComponent />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WelcomePage;
