import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTranscriptText,
  addTranscriptToHistory,
  navigateTranscriptHistory,
  editCurrentTranscript,
  clearTranscriptHistory,
  addTempTranscript,
} from "../../../redux/vocabSlice";
import Loader from "../../../components/Loader/Loader";
import HeaderComponent from "components/Header/Header";
import { useNavigate, useParams } from "react-router";
import images from "../../../images";
import Footer from "../../../components/Footer/Footer";

import {
  fetchTranscriptService,
  saveTranscript,
  submitTranscriptData,
  updateTranscript,
} from "../../../services/transcript.service";
import { toast } from "react-toastify";
import { UserContext } from "../../../providers/UserContext";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { cleanTranscript } from "../../../utils/transcriptUtils";
import TranscriptTextEditHeighlight from "./components/TranscriptText";
import RegenerateTranscript from "../../../components/Modal/RegenerateTranscript/RegenerateTranscript";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";
function EditTranscriptGenerated() {
  const { currentUser } = useContext(UserContext);
  const [usertoken] = useState(currentUser.access || "");
  const [isEditing, setIsEditing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showErorModal, setShowErorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const identifier = id;
  const [loading, setLoading] = useState(false);
  const transcriptText = useSelector((state) => state.vocab.transcriptText);
  const transcriptDetail = useSelector((state) => state.vocab.tempTranscript);
  const [showRegenerateTranscriptModal, setShowRegenerateTranscriptModal] =
    useState(false);

  const [keywords, setKeywords] = useState([]);

  const transcriptHistory = useSelector(
    (state) => state.vocab.transcriptHistory
  );
  const currentTranscriptIndex = useSelector(
    (state) => state.vocab.currentTranscriptIndex
  );
  const transcriptRef = useRef(null);

  const dispatch = useDispatch();
  const closeModal = () => {
    regenerateTranscript();
    setShowErorModal(false);
  };
  useEffect(() => {
    console.log("Transcript History:", transcriptHistory);
  }, [transcriptHistory, currentUser]);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(editCurrentTranscript({ newTranscript: transcriptText }));
  };

  const handlePrevClick = () => {
    dispatch(navigateTranscriptHistory({ direction: "prev" }));
  };

  const handleNextClick = () => {
    dispatch(navigateTranscriptHistory({ direction: "next" }));
  };
  useEffect(() => {
    // const token = localStorage.getItem("accessToken");

    const fetchTranscriptText = async () => {
      try {
        const data = await fetchTranscriptService(id, currentUser.access);
        if (data) {
          setKeywords(data.keyword.keywords_list);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);
  useEffect(() => {
    // Push a new entry to the history stack on mount
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Check if the popstate event is triggered
      if (event.state) {
        // Redirect to the current page to prevent back navigation
        window.history.pushState(null, document.title, window.location.href);
        // Optionally, you can redirect to a specific page
        // window.location.replace("/your-specific-page");
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleCancelClick = () => setIsEditing(false);

  const handleChange = (e) => {
    const updatedText = e.target.value;
    dispatch(setTranscriptText(updatedText.replace(/\n/g, "<br/>")));
  };
  const saveTranscriptClick = async () => {
    try {
      setLoading(true);
      // const accessToken = localStorage.getItem("accessToken");
      // const identifier = localStorage.getItem("practiceIdentifier");

      if (!currentUser.access || !identifier) {
        console.error("Missing access token or practice identifier.");
        return;
      }

      let transcript = Array.isArray(transcriptText)
        ? transcriptText.join(" ")
        : transcriptText;
      transcript = transcript.replace(/<br\/>\s*<br\/>/g, "<break/>");
      const dataToSend = {
        identifier: identifier,
        transcript_length: transcriptDetail.transcript_length,
        additional_instructions: transcriptDetail.additional_instructions,
        speaker: transcriptDetail.speaker,
        transcript: transcript,
        conversation_type: transcriptDetail.conversation_type,
        person1: transcriptDetail.person1,
        person1_details: transcriptDetail.person1_details,
        person2: transcriptDetail.person2,
      };

      console.log(dataToSend, "dataToSend");
      const data = await updateTranscript(
        currentUser.access,
        identifier,
        dataToSend
      );

      // console.log("Transcript saved successfully:", data);
      toast.success("Transcript Updated Successfully");
      navigate(`/voice-catalog/${id}`, {
        state: { transcript: transcriptText },
      });
      dispatch(clearTranscriptHistory());
    } catch (error) {
      console.error("Error saving transcript:", error);
    } finally {
      setLoading(false);
    }
  };

  const regenerateTranscriptModal = () => {
    if (transcriptHistory.length > 2) {
      setShowModal(true);
    } else {
      setShowRegenerateTranscriptModal(true);
    }
  };

  const regenerateTranscript = async (feedbacks) => {
    const maxRetries = 2;
    let attempt = 0;
    let success = false;
    setShowRegenerateTranscriptModal(false);
    if (transcriptHistory.length > 2) {
      setShowModal(true);
      return;
    }
    while (attempt <= maxRetries && !success) {
      try {
        setLoading(true);
        // const accessToken = localStorage.getItem("accessToken");
        // const practiceIdentifier = localStorage.getItem("practiceIdentifier");
        console.log(transcriptDetail);
        const dataToSend = {
          identifier: identifier,
          transcript_length: transcriptDetail.transcript_length,
          additional_instructions: transcriptDetail.additional_instructions,
          speaker: transcriptDetail.speaker,
          conversation_type: transcriptDetail.conversation_type,
          person1: transcriptDetail.person1,
          person1_details: transcriptDetail.person1_details,
          person2: transcriptDetail.person2,
          feedbacks: feedbacks,
        };
        const data = await submitTranscriptData(dataToSend, currentUser.access);
        const newTranscript = data.transcript;
        toast.success("Transcript Generated");
        if (
          !transcriptHistory.some(
            (entry) => entry.transcript === transcriptText
          )
        ) {
          dispatch(
            addTranscriptToHistory({
              transcript: transcriptText,
              identifier: identifier,
            })
          );
        }

        const transcriptUpdated = {
          ...transcriptDetail,
          transcript: newTranscript,
        };
        success = true;
        dispatch(addTempTranscript(transcriptUpdated));
        // Update the transcript with the newly generated one
        dispatch(setTranscriptText(newTranscript));
        dispatch(
          addTranscriptToHistory({
            transcript: newTranscript,
            identifier: identifier,
          })
        );
        handleScrollToTop();
        success = true;
        break;
      } catch (error) {
        if (attempt >= maxRetries) {
          console.error(
            "Error regenerating transcript after multiple attempts:",
            error
          );
          toast.error(
            "Right now I am unable to regenerate the transcript. Please try again later."
          );
        } else {
          console.error("Error regenerating transcript, retrying...", error);
        }
      } finally {
        attempt += 1;
        setLoading(false);
      }
    }
  };

  const opscloseModal = () => {
    setShowModal(false);
    setShowRegenerateTranscriptModal(false);
  };

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  // const formattedTranscript = transcriptArray.map((paragraph) =>
  //   paragraph.replace(/<break \/>/g, "\n \n")
  // );
  const formattedTranscript = cleanTranscript(transcriptArray);

  // Function to enter fullscreen
  const enterFullScreen = () => {
    if (transcriptRef.current.requestFullscreen) {
      transcriptRef.current.requestFullscreen();
    } else if (transcriptRef.current.mozRequestFullScreen) {
      transcriptRef.current.mozRequestFullScreen(); // Firefox
    } else if (transcriptRef.current.webkitRequestFullscreen) {
      transcriptRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
    } else if (transcriptRef.current.msRequestFullscreen) {
      transcriptRef.current.msRequestFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };

  // Function to exit fullscreen
  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome, Safari and Opera
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };

  // Scroll to top on button click
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scroll
    });
  };
  const textareaRef = useRef(null); // Ref to target textarea

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.focus(); // Set focus to textarea

      // Move the cursor to the end of the text
      const end = textarea.value.length;
      textarea.setSelectionRange(end, end);

      // Scroll to the bottom to make sure cursor is visible
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, [isEditing]);
  return (
    <div className="min-screen Transcript">
      <HeaderComponent />
      <div className="edittranscript-mainContentBox">
        <div className="trancript-container">
          <h1 className="title">Transcript Generation</h1>

          <div
            className={`trancsript-scollbar ${
              isFullScreen ? "fullScreenBox" : ""
            }`}
            ref={transcriptRef}
          >
            <div className="text-right">
              {isFullScreen ? (
                <button onClick={exitFullScreen} className="fullscreen-button">
                  Exit Fullscreen
                </button>
              ) : (
                <button onClick={enterFullScreen} className="fullscreen-button">
                  View Fullscreen
                </button>
              )}
            </div>
            {/* <textarea
              className="text scrollbar"
              readOnly={!isEditing}
              value={formattedTranscript}
              onChange={handleChange}
            /> */}

            {formattedTranscript &&
              keywords &&
              (isEditing ? (
                <div className="card mb-4">
                  <div
                    className="card-header"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img className="my-2" src={images.editpage}></img>
                    <h4 className="my-2 edit-transcript">Edit Transcript</h4>
                  </div>

                  <div className="card-body">
                    <textarea
                      className="text scrollbar m-0 textarea-transcript"
                      readOnly={!isEditing}
                      keywords={keywords}
                      ref={textareaRef}
                      // value={formattedTranscript}
                      value={formattedTranscript.replace(
                        /<\/?br\s*\/?>/g,
                        "\n"
                      )}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ) : (
                <TranscriptTextEditHeighlight
                  className="text scrollbar"
                  text={formattedTranscript}
                  keywords={keywords}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              ))}
          </div>

          <div className="btn-list">
            {isEditing ? (
              <>
                <button
                  onClick={handleSaveClick}
                  className="btn btn-light edit"
                >
                  SAVE
                </button>
                <button
                  onClick={handleCancelClick}
                  className="btn btn-light edit"
                >
                  CANCEL
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleEditClick}
                  className="btn btn-light edit"
                >
                  EDIT
                </button>
                <div className="prenext-div">
                  {transcriptHistory.length > 1 && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={handlePrevClick}
                      disabled={currentTranscriptIndex <= 0}
                    >
                      <img src={images.ArrowLeft} alt="ArrowLeft" />
                    </button>
                  )}

                  <button
                    onClick={regenerateTranscriptModal}
                    className="btn btn-light regenerate"
                  >
                    REGENERATE
                  </button>

                  {transcriptHistory.length > 1 && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={handleNextClick}
                      disabled={
                        currentTranscriptIndex >= transcriptHistory.length - 1
                      }
                    >
                      <img src={images.ArrowRight} alt="ArrowRight" />
                    </button>
                  )}
                </div>

                <button
                  onClick={saveTranscriptClick}
                  className="btn btn-light regenerate"
                >
                  CONTINUE WITH PRACTICE GENERATION
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {loading && (
        // <div className="loader-overlay">
        <Loader />
        // </div>
      )}
      {showErorModal && (
        <div onClick={closeModal}>
          <ErrorModalComponent modalText="Something went wrong! Please try again" />
        </div>
      )}
      <OpsModal show={showModal} onClose={opscloseModal} context="transcript" />

      {showRegenerateTranscriptModal && (
        <RegenerateTranscript
          show={showRegenerateTranscriptModal}
          type={"Transcript"}
          name={""}
          onClose={() => setShowRegenerateTranscriptModal(false)}
          onSubmit={regenerateTranscript}
          errorMessage={(errorMessage) => toast.error(errorMessage)}
        />
      )}
    </div>
  );
}

export default EditTranscriptGenerated;
