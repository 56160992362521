import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderWhiteComponent from "../../components/HeaderWhite/HeaderWhite";
import axios from "axios";
import {
  API_URL,
  REACT_APP_DELETE_RECORDING,
  REACT_APP_URL,
} from "../../constants";
import { useNavigate, useParams } from "react-router";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import Footer from "../../components/Footer/Footer";
import "./ViewRecordingWithKeyword.css";
import DeleteListModal from "../../components/Modal/AudioList/AudioList";
import { toast } from "react-toastify";
import {
  fetchRecordingsByIdentifier,
  fetchRecordingsService,
} from "../../services/audio.service";
import { fetchTranscriptService } from "../../services/transcript.service";
import { UserContext } from "../../providers/UserContext";
import TranscriptText from "./components/TranscriptText";
import images from "../../images";
import { deleteAllRecording } from "../../services/api.service";

const ViewRecordingWithKeyword = () => {
  const { id, audioId } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [transcriptText, setTranscriptText] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const { currentUser } = useContext(UserContext);

  const [selectedAudioId, setSelectedAudioId] = useState("");
  const [selectedAudioFile, setSelectedAudioFile] = useState("");
  const [audioPlay, setAudioPlay] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const audioRefs = useRef([]);
  const audioPlayerRef = useRef(null); // Using useRef for the AudioPlayerBox
  const accessToken = localStorage.getItem("accessToken");

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    // Push a new entry to the history stack on mount
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Check if the popstate event is triggered
      if (event.state) {
        // Redirect to the current page to prevent back navigation
        window.history.pushState(null, document.title, window.location.href);
        // Optionally, you can redirect to a specific page
        // window.location.replace("/your-specific-page");
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const fetchRecordings = async () => {
      if (!accessToken) {
        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
        if (!redirectAfterLogin) {
          const redirectUrl = window.location.pathname; // Get the current URL
          console.log("Redirecting to login from", redirectUrl);
          localStorage.setItem("redirectAfterLogin", redirectUrl); // Store the URL
        }
        navigate("/login"); // Redirect to the login page
        return; // Exit the function early to prevent further execution
      }
      try {
        const response = await fetchRecordingsByIdentifier(
          // currentUser.email,
          id,
          currentUser.access
        );
        if (response && Array.isArray(response.data)) {
          // Find the audio matching the audioId from the URL
          const selectedAudio = response.data.find(
            (audio) => audio.id === Number(audioId)
          );

          if (selectedAudio) {
            // Set only the matching audio in the dropdown
            setAudioList([selectedAudio]); // Show only the selected audio

            // Auto-select the audio and set the audio file to play
            setSelectedAudioId(selectedAudio.id);
            setSelectedAudioFile(`${API_URL}${selectedAudio.recording_file}`);
          }
        } else {
          console.error("Unexpected data format", response);
        }
      } catch (error) {
        console.error("Error fetching the recordings:", error);
      }
    };

    fetchRecordings();
  }, [id, currentUser, audioId]);

  const handleAudioSelection = (event) => {
    const selectedId = Number(event.target.value);
    setSelectedAudioId(selectedId);

    const selectedAudio = audioList.find((audio) => audio.id === selectedId);
    if (selectedAudio) {
      setSelectedAudioFile(`${API_URL}${selectedAudio.recording_file}`);
      setAudioPlay(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(
        REACT_APP_DELETE_RECORDING,
        { id },
        {
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        }
      );

      const updatedList = audioList.filter((audio) => audio.id !== id);
      setAudioList(updatedList);
      toast.success("Audio Recording Deleted");
      setShowDeleteModal(false);
      if (id === selectedAudioId) {
        setSelectedAudioId(updatedList.length > 0 ? updatedList[0].id : null);
        setSelectedAudioFile(
          updatedList.length > 0 ? `${API_URL}${updatedList[0].file}` : ""
        );
      }
    } catch (error) {
      console.error("Error deleting the audio:", error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      const response = await deleteAllRecording(
        id,
        currentUser.email,
        currentUser.access
      );
      if (response) {
        setShowDeleteModal(false);
        setAudioList([]);
      }
    } catch (error) {
      console.error("Error deleting the audio:", error);
    }
  };

  const audioToggle = async () => {
    setAudioPlay(!audioPlay);
  };

  useEffect(() => {
    const fetchTranscriptText = async () => {
      try {
        const data = await fetchTranscriptService(id, currentUser.access);
        if (data) {
          setKeywords(data.keyword.keywords_list);
          setTranscriptText(data.transcript.transcript);
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
    };

    if (id && currentUser && currentUser.access) {
      fetchTranscriptText();
    }
  }, [id, currentUser]);

  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) =>
      paragraph
        .replace(/<break \/>/g, "\n \n")
        .replace(/<break\/>/g, "\n \n")
        .replace(/\*/g, "")
    )
    .join("\n\n");

  const openEvaluteNow = () => {
    navigate(`/audio-recording-player/${id}`);
  };

  const backClick = () => {
    navigate(`/recording/${id}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (audioPlayerRef.current) {
        const sticky = audioPlayerRef.current.offsetTop;

        if (window.pageYOffset > sticky) {
          setIsSticky(true); // Add sticky class when scrolled past
        } else {
          setIsSticky(false); // Remove sticky class
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="min-screen recordingContent">
      <HeaderWhiteComponent className="headerr" />
      <div style={{ backgroundColor: "#fff" }}>
        <div className="recording-with-key-mainContentBox">
          <div className="recordingKeywordBox">
            <div className="RecordingKeywordContent">
              <div className="topBarcontent">
                <div className="topBarcontentRight">
                  <div className="userName">
                    <button className="btn btn-primary" onClick={backClick}>
                      <img
                        className="back-dash-right"
                        src={images.ArrowLeftwhite}
                        alt="Back"
                      />
                      Back
                    </button>
                  </div>
                </div>
                {/* <div className="keyword-btn-div">
                  <div className="topBarcontentRight">
                    {audioList.length > 0 ? (
                      <div className="userName">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleDeleteClick(id)}
                        >
                          Delete recording(s)
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
              </div>

              <div className="RecordingMidContent">
                <div className="midLeft">
                  <img src={images.bigLogo} alt="logo" className="logoImg" />
                </div>

                {audioList.length > 0 ? (
                  <div className="midRight custom-select-wrapper">
                    <select
                      className="form-control custom-select mb-3"
                      value={selectedAudioId}
                      onChange={handleAudioSelection}
                    >
                      <option value="">-- select recording --</option>
                      {audioList.map((audio) => (
                        <option key={audio.id} value={audio.id}>
                          {audio.recording_name}
                        </option>
                      ))}
                    </select>

                    <div
                      id="audioPlayerBox"
                      ref={audioPlayerRef} // Assign ref to AudioPlayerBox
                      className={`audio-item ${
                        isSticky ? "audioPlayerSticky" : ""
                      }`} // Toggle sticky class
                      style={{ padding: "0" }}
                    >
                      <div className="AudioPlayerBox">
                        {selectedAudioFile && (
                          <AudioPlayer
                            index={selectedAudioId}
                            audioData={audioList}
                            REACT_APP_URL={REACT_APP_URL}
                            audioRefs={audioRefs}
                            src={selectedAudioFile}
                            play={audioPlay}
                            playStatus={audioToggle}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="midRight">
                    {formattedTranscript && keywords && (
                      <p>
                        This recording is no longer available. It was deleted by
                        the owner.{" "}
                      </p>
                    )}
                    {/* <button onClick={openEvaluteNow}>Evaluate Now</button> */}
                  </div>
                )}
              </div>

              <div className="recordingDescription">
                {formattedTranscript && keywords && (
                  <TranscriptText
                    text={formattedTranscript}
                    keywords={keywords}
                    callbackForAudio={audioToggle}
                  />
                )}
              </div>

              <Footer />
              <DeleteListModal
                show={showDeleteModal}
                handleClose={closeModal}
                recordings={audioList}
                handleDelete={handleDelete}
                handleDeleteAll={handleDeleteAll}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRecordingWithKeyword;
