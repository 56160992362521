import React, { useRef, useEffect, useState } from "react";
import "./AudioPlayer.css";

const AudioPlayer = ({ toggles, index, src, play, playStatus }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [seeking, setSeeking] = useState(false);

  const timeString = (secs) => {
    let ss = Math.floor(secs);
    let mm = Math.floor(ss / 60);
    ss = ss - mm * 60;
    return `${mm}:${ss < 10 ? "0" + ss : ss}`;
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && src) {
      audio.src = src;

      audio.onloadedmetadata = () => {
        if (audio.duration !== Infinity) {
          setDuration(audio.duration);
        }
      };

      audio.onended = () => {
        setIsPlaying(false);
        setCurrentTime(0);
        playStatus(false);
      };
    }

    const handleTimeUpdate = () => {
      if (!seeking) {
        setCurrentTime(audio.currentTime);
      }
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.pause();
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.src = "";
    };
  }, [src, seeking]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => console.error("Playback failed:", error));
    }
    setIsPlaying(!isPlaying);
    playStatus(!isPlaying);
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (play) {
      audio.play().then(() => setIsPlaying(true));
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  }, [play]);

  const handleSeekChange = (e) => {
    const audio = audioRef.current;
    const seekTime = e.target.value;
    audio.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleVolumeChange = (e) => {
    const volumeValue = e.target.value;
    setVolume(volumeValue);
    audioRef.current.volume = volumeValue;
  };

  const handleIconClick = () => {
    const newVolume = volume > 0 ? 0 : 1;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };
  const sliderStyle = {
    background: `linear-gradient(to right, white ${
      (currentTime / duration) * 100
    }%, #313131 ${(currentTime / duration) * 100}%)`,
  };
  return (
    <div
      className={`audio-player ${
        toggles && toggles[index] ? "active-green" : ""
      }`}
    >
      <button className="play-pause-button" onClick={handlePlayPause}>
        <span
          className={`icon ${isPlaying ? "bi bi-pause" : "bi bi-play"}`}
          style={{ color: "white" }}
        ></span>
      </button>
      <div className="slider-container">
        <input
          className="slider"
          type="range"
          min="0"
          max={duration}
          step="0.1"
          value={currentTime}
          onChange={handleSeekChange}
          style={sliderStyle}
        />
        <div className="time-info">
          {timeString(currentTime)} /{" "}
          {duration > 0 ? timeString(duration) : "Loading..."}
        </div>
      </div>
      <div className="volume-container">
        <span
          className={`volume-icon Heading ${
            volume > 0 ? "bi bi-volume-up" : "bi bi-volume-mute"
          }`}
          style={{
            marginRight: "5px",
            cursor: "pointer",
            fontSize: "22px",
            color: "white",
          }}
          onClick={handleIconClick}
        ></span>
        <input
          className="volume-bar"
          type="range"
          min="0"
          max="1"
          value={volume}
          step="0.1"
          onChange={handleVolumeChange}
        />
      </div>
      <audio ref={audioRef} />
    </div>
  );
};

export default AudioPlayer;
